import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';

import {
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'src/context/user.context';
import {
  IExperience,
  IUser,
} from 'src/services/user-service/user-service.interface';
import {
  deleteExperience,
  fetchExperience,
  fetchExperiencedUser,
} from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import ExperienceForm from './experience-form';
import styles from './profile.module.css';
import WorkForce from '../search/searchList/WorkForce';
import SignupModal from '../stepper/SignupModal';

type IProps = {
  user: IUser;
};
interface UserInfo {
  user: IUser[];
  _userId: string;
  name: string;
  position: string;
  endedOn?: string;
}
type IContextMenuProps = {
  onDelete: () => void;
  onEdit: () => void;
  loading?: boolean;
};

const ContextMenu = ({
  onEdit,
  onDelete,
  loading = false,
}: IContextMenuProps) => {
  const [show, setShow] = useState(false);

  return (
    <div className={styles['item-interaction-right']}>
      <>
        <Dropdown
          className={styles.menuButton}
          isOpen={show}
          toggle={() => setShow((old) => !old)}
          direction="down"
        >
          <DropdownToggle>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faEllipsisVertical} />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={onDelete}>Delete</DropdownItem>
            <DropdownItem onClick={onEdit}>Edit</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </>
    </div>
  );
};

const JobsOccupation = ({ user }: IProps) => {
  const { id } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [experienceData, setExperienceData] = useState<Partial<IExperience>>(
    {}
  );
  const [experiences, setExperiences] = useState<Array<IExperience>>([]);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const profileId = query.get('profileId');

  const getExperience = async (profileId: string) => {
    setLoading(true);
    const response = await fetchExperience(profileId as string);
    setExperiences(response.data);
    setLoading(false);
  };
  const self = id === profileId;
  const [isJob, setIsJob] = useState(true);
  const [isPractice, setIsPractice] = useState(false);
  const [isOthers, setIsOthers] = useState(false);

  const jobsData = experiences.filter(({ type }) => type === 'JOB');
  const practiceData = experiences.filter(({ type }) => type === 'PRACTICE');
  const occupationData = experiences.filter(({ type }) => type === 'OTHER');
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  //workforce
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userInfos, setUserInfos] = useState<UserInfo[]>([]);

  const [currentOrgName, setCurrentOrgName] = useState('');

  const handleClick = async (orgName: any) => {
    setCurrentOrgName(() => orgName);

    try {
      const response = await fetchExperiencedUser(orgName || '');

      if (response && Array.isArray(response.userInfos)) {
        setUserInfos(response.userInfos);
      } else {
        console.error('Unexpected data format:', response);
        setUserInfos([]);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error handling click:', error);
      setUserInfos([]);
    }
  };

  useEffect(() => {
    if (profileId) {
      getExperience(profileId);
    }
  }, [profileId]);

  const onDelete = async (experienceId: string) => {
    setLoading(true);
    if (profileId) {
      await deleteExperience(profileId, experienceId);
    }
    setExperiences((prev) => prev.filter(({ _id }) => _id !== experienceId));
    setLoading(false);
  };
  const handleJob = () => {
    setIsPractice(false);
    setIsOthers(false);
    setIsJob(true);
  };
  const handlePractice = () => {
    setIsOthers(false);
    setIsJob(false);
    setIsPractice(true);
  };
  const handleOthers = () => {
    setIsJob(false);
    setIsPractice(false);
    setIsOthers(true);
  };

  useEffect(() => {
    if (!self) {
      if (jobsData.length === 0) {
        if (practiceData.length > 0) {
          setIsJob(false);
          setIsPractice(true);
        }
        if (practiceData.length === 0 && occupationData.length > 0) {
          setIsOthers(true);
          setIsJob(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceData.length, occupationData.length]);

  return (
    <>
      <div className={styles['jobs-occupation']}>
        {loading ? (
          <LoadingLottie height="350px" />
        ) : (
          <Row>
            {(self || jobsData.length > 0) && (
              <Col sm={12} md={4} className={styles['jo-item-container']}>
                {jobsData.map((item, idx) => (
                  <div className={styles['jo-item']} key={idx}>
                    <div className={styles['jo-item-header']}>
                      Job {idx + 1}
                    </div>
                    <div className={styles['jo-item-detail']}>
                      <p
                        className={classNames(
                          styles['detail-text'],
                          styles.heading
                        )}
                      >
                        <span
                          onClick={() =>
                            !token
                              ? setSignupModal(true)
                              : handleClick(item.name)
                          }
                        >
                          {item.name}
                        </span>
                        {(self ||
                          ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                            user?.role
                          )) && (
                          <ContextMenu
                            onEdit={() => setExperienceData({ ...item })}
                            onDelete={() => onDelete(item._id)}
                          />
                        )}
                      </p>
                      <p className={styles['detail-text']}>
                        {item.designation}
                      </p>
                      <p className={styles['detail-text']}>
                        {moment(item.startedOn).format('MM/YYYY')}
                        {item.endedOn
                          ? `${' '}- ${moment(item.endedOn).format('MM/YYYY')}`
                          : '  - Present'}
                      </p>
                    </div>
                  </div>
                ))}
                {jobsData.length < 5 && self && (
                  <div
                    className={styles['jo-item']}
                    onClick={() =>
                      setExperienceData({
                        type: 'JOB',
                      })
                    }
                  >
                    <div className={styles['jo-item-header']}>
                      Job {jobsData.length + 1}
                    </div>
                    <div
                      className={classNames(
                        styles['jo-item-detail'],
                        styles['new-add-btn']
                      )}
                    >
                      + New Job
                    </div>
                  </div>
                )}
              </Col>
            )}
            {(self || practiceData.length > 0) && (
              <Col sm={12} md={4} className={styles['jo-item-container']}>
                {practiceData.map((item, idx) => (
                  <div className={styles['jo-item']} key={idx}>
                    <div className={styles['jo-item-header']}>
                      Practice {idx + 1}
                    </div>
                    <div className={styles['jo-item-detail']}>
                      <p
                        className={classNames(
                          styles['detail-text'],
                          styles.heading
                        )}
                      >
                        {item?.name}
                        {(self ||
                          ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                            user?.role
                          )) && (
                          <ContextMenu
                            onEdit={() => setExperienceData({ ...item })}
                            onDelete={() => onDelete(item._id)}
                          />
                        )}
                      </p>
                      <p className={styles['detail-text']}>
                        {item.designation}
                      </p>
                      <p className={styles['detail-date']}>
                        {moment(item.startedOn).format('MM/YYYY')}
                        {item.endedOn
                          ? `${' '}- ${moment(item.endedOn).format('MM/YYYY')}`
                          : '  - Present'}
                      </p>
                    </div>
                  </div>
                ))}
                {practiceData.length < 5 && self && (
                  <div
                    className={styles['jo-item']}
                    onClick={() =>
                      setExperienceData({
                        type: 'PRACTICE',
                      })
                    }
                  >
                    <div className={styles['jo-item-header']}>
                      Practice {practiceData.length + 1}
                    </div>
                    <div
                      className={classNames(
                        styles['jo-item-detail'],
                        styles['new-add-btn']
                      )}
                    >
                      + New Practice
                    </div>
                  </div>
                )}
              </Col>
            )}
            {(self || occupationData.length > 0) && (
              <Col sm={12} md={4} className={styles['jo-item-container']}>
                {occupationData.map((item, idx) => (
                  <div className={styles['jo-item']} key={idx}>
                    <div className={styles['jo-item-header']}>
                      Other Occupation
                    </div>
                    <div className={styles['jo-item-detail']}>
                      <p
                        className={classNames(
                          styles['detail-text'],
                          styles.heading
                        )}
                      >
                        {item.name}
                        {(self ||
                          ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                            user?.role
                          )) && (
                          <ContextMenu
                            onEdit={() => setExperienceData({ ...item })}
                            onDelete={() => onDelete(item._id)}
                          />
                        )}
                      </p>
                      <p className={styles['detail-text']}>
                        {item.designation}
                      </p>
                      <p className={styles['detail-text']}>
                        {moment(item.startedOn).format('MM/YYYY')}
                        {item.endedOn
                          ? `${' '}- ${moment(item.endedOn).format('MM/YYYY')}`
                          : '  - Present'}
                      </p>
                    </div>
                  </div>
                ))}
                {occupationData.length < 5 && self && (
                  <div
                    className={styles['jo-item']}
                    onClick={() =>
                      setExperienceData({
                        type: 'OTHER',
                      })
                    }
                  >
                    <div className={styles['jo-item-header']}>
                      Other Occupation
                    </div>
                    <div
                      className={classNames(
                        styles['jo-item-detail'],
                        styles['new-add-btn']
                      )}
                    >
                      + New Occupation
                    </div>
                  </div>
                )}
              </Col>
            )}
          </Row>
        )}

        {!!Object.keys(experienceData).length && (
          <ExperienceForm
            user={user}
            show={!!Object.keys(experienceData).length}
            onHide={() => setExperienceData({})}
            initialExperienceData={experienceData}
            onCreate={(experience) =>
              setExperiences((prev) => [...prev, experience])
            }
            onUpdateExperience={(experience) => {
              let updatedExperiences = [...experiences];
              updatedExperiences = updatedExperiences.filter(
                ({ _id }) => _id !== experience._id
              );
              updatedExperiences = [...updatedExperiences, experience];

              setExperiences(updatedExperiences);
            }}
          />
        )}
      </div>
      <div className={styles['jobs-occupation-mobile']}>
        {self && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              background: 'var(--grey-grey-80, #333)',
              padding: '8px 4px 0 4px',
              alignItems: 'center',

              margin: '0 -24px 20px -24px',
            }}
          >
            <div
              onClick={() => {
                handleJob();
              }}
              className={classNames(styles.job, {
                [styles['job-active']]: isJob,
              })}
            >
              Job
            </div>
            <div
              onClick={() => {
                handlePractice();
              }}
              className={classNames(styles.job, {
                [styles['job-active']]: isPractice,
              })}
            >
              Practice
            </div>
            <div
              onClick={() => {
                handleOthers();
              }}
              className={classNames(styles.job, {
                [styles['job-active']]: isOthers,
              })}
            >
              Others
            </div>
          </div>
        )}
        {!self &&
          (jobsData.length > 0 ||
            practiceData.length > 0 ||
            occupationData.length > 0) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                background: 'var(--grey-grey-80, #333)',
                padding: '8px 4px 0 4px',
                alignItems: 'center',
                margin: '0 -24px 20px -24px',
              }}
            >
              {jobsData.length > 0 && (
                <div
                  onClick={() => {
                    handleJob();
                  }}
                  className={classNames(styles.job, {
                    [styles['job-active']]: isJob,
                  })}
                >
                  Job
                </div>
              )}

              {practiceData.length > 0 && (
                <div
                  onClick={() => {
                    handlePractice();
                  }}
                  className={classNames(styles.job, {
                    [styles['job-active']]: isPractice,
                  })}
                >
                  Practice
                </div>
              )}

              {occupationData.length > 0 && (
                <div
                  onClick={() => {
                    handleOthers();
                  }}
                  className={classNames(styles.job, {
                    [styles['job-active']]: isOthers,
                  })}
                >
                  Others
                </div>
              )}
            </div>
          )}

        {loading ? (
          <LoadingLottie height="350px" />
        ) : (
          <Row>
            {(self || jobsData.length > 0) && isJob && (
              <Col sm={12} md={4} className={styles['jo-item-container']}>
                {jobsData.map((item, idx) => (
                  <div className={styles['jo-item']} key={idx}>
                    <div className={styles['jo-item-header']}>
                      Job {idx + 1}
                    </div>
                    <div
                      className={styles['jo-item-detail']}
                      onClick={() => handleClick(item.name)}
                    >
                      <p
                        className={classNames(
                          styles['detail-text'],
                          styles.heading
                        )}
                      >
                        {item.name}
                        {(self ||
                          ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                            user?.role
                          )) && (
                          <ContextMenu
                            onEdit={() => setExperienceData({ ...item })}
                            onDelete={() => onDelete(item._id)}
                          />
                        )}
                      </p>
                      <p className={styles['detail-text']}>
                        {item.designation}
                      </p>
                      <p className={styles['detail-text']}>
                        {moment(item.startedOn).format('MM/YYYY')}
                        {item.endedOn
                          ? `${' '}- ${moment(item.endedOn).format('MM/YYYY')}`
                          : '  - Present'}
                      </p>
                    </div>
                  </div>
                ))}
                {jobsData.length < 5 && self && (
                  <div
                    className={styles['jo-item']}
                    onClick={() =>
                      setExperienceData({
                        type: 'JOB',
                      })
                    }
                  >
                    <div className={styles['jo-item-header']}>
                      Job {jobsData.length + 1}
                    </div>
                    <div
                      className={classNames(
                        styles['jo-item-detail'],
                        styles['new-add-btn']
                      )}
                    >
                      + New Job
                    </div>
                  </div>
                )}
              </Col>
            )}
            {(self || practiceData.length > 0) && isPractice && (
              <Col sm={12} md={4} className={styles['jo-item-container']}>
                {practiceData.map((item, idx) => (
                  <div className={styles['jo-item']} key={idx}>
                    <div className={styles['jo-item-header']}>
                      Practice {idx + 1}
                    </div>
                    <div className={styles['jo-item-detail']}>
                      <p
                        className={classNames(
                          styles['detail-text'],
                          styles.heading
                        )}
                      >
                        {item.name}
                        {(self ||
                          ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                            user?.role
                          )) && (
                          <ContextMenu
                            onEdit={() => setExperienceData({ ...item })}
                            onDelete={() => onDelete(item._id)}
                          />
                        )}
                      </p>
                      <p className={styles['detail-text']}>
                        {item.designation}
                      </p>
                      <p className={styles['detail-text']}>
                        {moment(item.startedOn).format('MM/YYYY')}
                        {item.endedOn
                          ? `${' '}- ${moment(item.endedOn).format('MM/YYYY')}`
                          : '  - Present'}
                      </p>
                    </div>
                  </div>
                ))}
                {practiceData.length < 5 && self && (
                  <div
                    className={styles['jo-item']}
                    onClick={() =>
                      setExperienceData({
                        type: 'PRACTICE',
                      })
                    }
                  >
                    <div className={styles['jo-item-header']}>
                      Practice {practiceData.length + 1}
                    </div>
                    <div
                      className={classNames(
                        styles['jo-item-detail'],
                        styles['new-add-btn']
                      )}
                    >
                      + New Practice
                    </div>
                  </div>
                )}
              </Col>
            )}
            {(self || occupationData.length > 0) && isOthers && (
              <Col sm={12} md={4} className={styles['jo-item-container']}>
                {occupationData.map((item, idx) => (
                  <div className={styles['jo-item']} key={idx}>
                    <div className={styles['jo-item-header']}>
                      Other Occupation
                    </div>
                    <div className={styles['jo-item-detail']}>
                      <p
                        className={classNames(
                          styles['detail-text'],
                          styles.heading
                        )}
                      >
                        {item.name}
                        {(self ||
                          ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                            user?.role
                          )) && (
                          <ContextMenu
                            onEdit={() => setExperienceData({ ...item })}
                            onDelete={() => onDelete(item._id)}
                          />
                        )}
                      </p>
                      <p className={styles['detail-text']}>
                        {item.designation}
                      </p>

                      <p className={styles['detail-text']}>
                        {moment(item.startedOn).format('MM/YYYY')}
                        {item.endedOn
                          ? `${' '}- ${moment(item.endedOn).format('MM/YYYY')}`
                          : '  - Present'}
                      </p>
                    </div>
                  </div>
                ))}
                {occupationData.length < 5 && self && (
                  <div
                    className={styles['jo-item']}
                    onClick={() =>
                      setExperienceData({
                        type: 'OTHER',
                      })
                    }
                  >
                    <div className={styles['jo-item-header']}>
                      Other Occupation
                    </div>
                    <div
                      className={classNames(
                        styles['jo-item-detail'],
                        styles['new-add-btn']
                      )}
                    >
                      + New Occupation
                    </div>
                  </div>
                )}
              </Col>
            )}
          </Row>
        )}
        {!!Object.keys(experienceData).length && (
          <ExperienceForm
            user={user}
            show={!!Object.keys(experienceData).length}
            onHide={() => setExperienceData({})}
            initialExperienceData={experienceData}
            onCreate={(experience) =>
              setExperiences((prev) => [...prev, experience])
            }
            onUpdateExperience={(experience) => {
              let updatedExperiences = [...experiences];
              updatedExperiences = updatedExperiences.filter(
                ({ _id }) => _id !== experience._id
              );
              updatedExperiences = [...updatedExperiences, experience];

              setExperiences(updatedExperiences);
            }}
          />
        )}
      </div>
      {isModalOpen && (
        <WorkForce
          userInfos={userInfos}
          orgName={currentOrgName}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default JobsOccupation;
