import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './meetingAvailabilityModal.module.css';
import {
  addUserAvailabilitySlots,
  deleteUserMeetingSlot,
  toggleUserMeetingAvailability,
} from 'src/services/meetings-service/meetings-service';
import { IUser } from 'src/services/user-service/user-service.interface';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  daysOfWeek,
  fullDayName,
  generateTimeOptions,
  MeetingAvailablityModalProps,
  validateTime,
} from './meetingHepler';

const MeetingAvailablityModal: React.FC<MeetingAvailablityModalProps> = ({
  onClose,
  isOpen,
}) => {
  const [selectedDays, setSelectedDays] = useState<Day[]>([]);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [loggedInUser, setLoggedInUser] = useState<IUser | null>(null);
  const [searchParams] = useSearchParams();
  const loggedInUserId = searchParams.get('profileId') || '';
  const timeOptions = generateTimeOptions();
  const [isMeetingActive, setIsMeetingActive] = useState(false);
  const [defaultSlots, setDefaultSlots] = useState([
    { day: 'Mon', startTime: '10 AM', endTime: '8 PM' },
    { day: 'Tue', startTime: '10 AM', endTime: '8 PM' },
    { day: 'Wed', startTime: '10 AM', endTime: '8 PM' },
    { day: 'Thu', startTime: '10 AM', endTime: '8 PM' },
    { day: 'Fri', startTime: '10 AM', endTime: '8 PM' },
    { day: 'Sat', startTime: '10 AM', endTime: '8 PM' },
    { day: 'Sun', startTime: '10 AM', endTime: '8 PM' },
  ]);

  const getUserDetails = async () => {
    try {
      const userRes = await fetchUserDetails(loggedInUserId);
      setLoggedInUser(userRes);
      setIsMeetingActive(userRes?.isAvailableForMeetings);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  const toggleDaySelection = (day: Day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleDeleteDefaultSlot = async (index: number) => {
    const newUpdatedSlots = defaultSlots.filter((_, i) => i !== index);
    await addUserAvailabilitySlots(newUpdatedSlots, false);
    setDefaultSlots([]);
    getUserDetails();
  };

  const handleDeleteMeetingSlot = async (slotId: number) => {
    if (loggedInUser?.availability?.length === 1) {
      setDefaultSlots([]);
      await toggleUserMeetingAvailability(false);
    }
    await deleteUserMeetingSlot(slotId);
    getUserDetails();
  };

  const handleToggleMeeting = async () => {
    const newStatus = !isMeetingActive;
    setIsMeetingActive(newStatus);
    await toggleUserMeetingAvailability(newStatus);
  };

  const handleSave = async () => {
    if (!validateTime(startTime, endTime)) {
      return;
    }
    const selectedAvailability = selectedDays.map((day) => ({
      day: String(day),
      startTime,
      endTime,
    }));

    const availability = [
      ...defaultSlots.map((slot) => ({
        ...slot,
        day: String(slot.day),
      })),
      ...selectedAvailability,
    ];

    try {
      if (
        loggedInUser?.availability?.length === 0 ||
        loggedInUser?.availability === null ||
        loggedInUser?.availability === undefined
      ) {
        await addUserAvailabilitySlots(availability, true);
      } else {
        await addUserAvailabilitySlots(selectedAvailability, true);
      }
    } catch (error) {
      console.error('Error in saving the user availability:', error);
    }

    getUserDetails();
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {window.innerWidth > 700 ? (
        <>
          {isOpen && (
            <div className={styles.meetingAvailabilityParent}>
              <div className={styles.meetingToggleContainer}>
                <span className={styles.meetingToggleLabel}>
                  Turn On Meeting Window
                </span>

                <label className={styles.meetingToggleSwitch}>
                  <input
                    type="checkbox"
                    checked={isMeetingActive}
                    onChange={handleToggleMeeting}
                    className={styles.meetingToggleInput}
                  />
                  <span className={styles.meetingToggleSlider}></span>
                </label>
              </div>
              {isMeetingActive && (
                <div className={styles.meetingSlotsContainer}>
                  <div className={styles.modalTitle}>
                    {/* @ts-ignore */}
                    {loggedInUser?.availability?.length > 0 ||
                      (defaultSlots?.length > 0 && 'Selected Slots::')}
                  </div>{' '}
                  {/* @ts-ignore */}
                  {loggedInUser?.availability.length < 1 ? (
                    <div>
                      {defaultSlots.map((slot, index) => (
                        <div key={index} className={styles.slotListItem}>
                          <span style={{ width: '45px' }}>{slot?.day}</span>
                          <span>: </span>
                          <span
                            style={{
                              width: 'calc(100% - 140px)',
                              textAlign: 'center',
                            }}
                          >
                            {slot?.startTime}
                          </span>
                          <span style={{ width: '20px', textAlign: 'center' }}>
                            to
                          </span>
                          <span style={{ width: '60px', textAlign: 'center' }}>
                            {slot?.endTime}
                          </span>
                          <span onClick={() => handleDeleteDefaultSlot(index)}>
                            <FontAwesomeIcon
                              icon={faXmark}
                              style={{ color: '#e32400', cursor: 'pointer' }}
                            />
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {loggedInUser?.availability?.map((slot, index) => (
                        <div key={index} className={styles.slotListItem}>
                          <span style={{ width: '45px' }}>{slot?.day}</span>
                          <span>: </span>
                          <span
                            style={{
                              width: 'calc(100% - 140px)',
                              textAlign: 'center',
                            }}
                          >
                            {slot?.startTime}
                          </span>
                          <span style={{ width: '20px', textAlign: 'center' }}>
                            to
                          </span>
                          <span style={{ width: '60px', textAlign: 'center' }}>
                            {slot?.endTime}
                          </span>
                          <span
                            onClick={() => handleDeleteMeetingSlot(slot?.id)}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              style={{ color: '#e32400', cursor: 'pointer' }}
                            />
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <div className={styles.modalTitle}>Working Days:</div>
              <div className={styles.checkboxGroup}>
                {daysOfWeek.map((day: any) => (
                  <label key={day} className={styles.dayLabel}>
                    <input
                      type="checkbox"
                      checked={selectedDays.includes(day)}
                      onChange={() => toggleDaySelection(day)}
                    />
                    {day}
                  </label>
                ))}
              </div>
              <div className={styles.modalTitle}>Working Hours:</div>
              <div className={styles.timePicker}>
                <select
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                >
                  <option value="" disabled>
                    Start Time
                  </option>
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>

                <select
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                >
                  <option value="" disabled>
                    End Time
                  </option>
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className={`${styles.button} ${styles.saveButton}`}
                onClick={handleSave}
                // disabled={isSaveDisabled}
              >
                Save
              </button>
            </div>
          )}
        </>
      ) : (
        <Modal
          show={isOpen}
          onHide={onClose}
          centered
          className={styles.meetingsModal}
        >
          <Modal.Body className={styles.meetingsModalBody}>
            <div className={styles.meetingAvailabilityParent}>
              <div className={styles.meetingToggleContainer}>
                <span className={styles.meetingToggleLabel}>
                  Turn On Meeting Window
                </span>

                <label className={styles.meetingToggleSwitch}>
                  <input
                    type="checkbox"
                    checked={isMeetingActive}
                    onChange={handleToggleMeeting}
                    className={styles.meetingToggleInput}
                  />
                  <span className={styles.meetingToggleSlider}></span>
                </label>
              </div>
              {isMeetingActive && (
                <div className={styles.meetingSlotsContainer}>
                  <div className={styles.modalTitle}>
                    {/* @ts-ignore */}
                    {loggedInUser?.availability?.length > 0 ||
                      (defaultSlots?.length > 0 && 'Selected Slots:')}
                  </div>{' '}
                  {/* @ts-ignore */}
                  {loggedInUser?.availability.length < 1 ? (
                    <div className={styles.slotTable}>
                      {defaultSlots.map((slot, index) => (
                        <div key={index} className={styles.slotTableRow}>
                          <span>{fullDayName[slot.day]}</span>
                          <span
                            style={{
                              minWidth: 'max-content',
                              margin: '0 10px',
                            }}
                          >
                            {slot.startTime} &nbsp; to &nbsp; {slot.endTime}
                          </span>

                          <span
                            onClick={() => handleDeleteDefaultSlot(index)}
                            className={styles.deleteIcon}
                            style={{
                              maxWidth: '40px',
                              width: '40px',
                              marginRight: '10px',
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <ol style={{ paddingLeft: '10px' }}>
                      {loggedInUser?.availability?.map((slot, index) => (
                        <div key={index} className={styles.slotTableRow}>
                          <span>{fullDayName[slot.day]}</span>
                          <span
                            style={{
                              minWidth: 'max-content',
                              margin: '0 10px',
                            }}
                          >
                            {slot.startTime} &nbsp; to &nbsp; {slot.endTime}
                          </span>

                          <span
                            onClick={() => handleDeleteMeetingSlot(slot?.id)}
                            className={styles.deleteIcon}
                            style={{
                              maxWidth: '40px',
                              width: '40px',
                              marginRight: '10px',
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      ))}
                    </ol>
                  )}
                </div>
              )}
              <div className={styles.modalTitle}>Working Days:</div>
              <div className={styles.checkboxGroup}>
                {daysOfWeek.map((day: any) => (
                  <label key={day} className={styles.dayLabel}>
                    <input
                      type="checkbox"
                      checked={selectedDays.includes(day)}
                      onChange={() => toggleDaySelection(day)}
                    />
                    {day}
                  </label>
                ))}
              </div>
              <div className={styles.modalTitle}>Working Hours:</div>
              <div className={styles.timePicker}>
                <select
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                >
                  <option value="" disabled>
                    Start Time
                  </option>
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>

                <select
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                >
                  <option value="" disabled>
                    End Time
                  </option>
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className={`${styles.button} ${styles.saveButton}`}
                onClick={handleSave}
                // disabled={isSaveDisabled}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default MeetingAvailablityModal;
