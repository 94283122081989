import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { createMenuData } from 'src/services/meta-data-service/meta-data-service';
import { Button } from 'react-bootstrap';
import styles from './createSignature.module.css';

type Props = {
  isOpen: any;
  onClose: any;
  onCreate: any;
  defaultCategory?: string;
};
const CreateSignatureModal = ({
  isOpen,
  onClose,
  onCreate,
  defaultCategory,
}: Props) => {
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  useEffect(() => {
    if (defaultCategory) {
      setCategory(defaultCategory);
      // Set the category when modal opens with the provided inputValue
    }
  }, [defaultCategory]);
  const handleCreate = async () => {
    try {
      await createMenuData(category, description);

      onCreate();
    } catch (error) {
      console.error('Error while creating the signature', error);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          onClose();
          setDescription('');
          setCategory('');
        }}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        className={styles.createSignModal}
      >
        <Modal.Header closeButton className={styles['category-modal-header']}>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.createSignContent}>
            <input
              type="text"
              placeholder="New Category Name"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className={styles['modal-input']}
            />
            <textarea
              placeholder="Provide Category Description (optional)"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={styles['modal-input-textarea']}
            />

            <div>
              <Button
                className={styles['model-submit']}
                onClick={handleCreate}
                disabled={!category}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateSignatureModal;
