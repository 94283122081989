import {
  faCalendarDays,
  faClock,
  faEllipsisVertical,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMeetings } from 'src/services/meetings-service/meeting-interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import styles from './meetingViewItem.module.css';
import {
  acceptCalendarInvite,
  declineCalendarInvite,
  deleteUserMeeting,
} from 'src/services/meetings-service/meetings-service';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { formatDateTime } from './meetingHepler';

type Props = {
  meeting: IMeetings;
  refreshMeetings: () => void;
};

export default function MeetingViewItem({ meeting, refreshMeetings }: Props) {
  const [client, setClient] = useState<IUser | null>(null);
  const [professional, setProfessional] = useState<IUser | null>(null);
  const loggedInUserId = localStorage.getItem('id') || '';
  const router = useNavigate();
  const [openMeetActions, setOpenMeetActions] = useState(false);
  const getUserDetails = async (userId: string): Promise<IUser | undefined> => {
    try {
      const userRes = await fetchUserDetails(userId);
      return userRes;
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  const handleAcceptInvite = async (meetingId: string) => {
    if (professional?.googleRefreshToken) {
      await acceptCalendarInvite(meetingId);
      refreshMeetings();
    } else {
      window.open(
        `${
          process.env.NODE_ENV === 'development'
            ? 'http://127.0.0.1:8080'
            : 'https://community.whitecollarprofessional.com'
        }/api/v1/auth/google/calendar`,
        '_blank'
      );
    }
  };

  const handleDeclineInvite = async (meetingId: string) => {
    if (professional?.googleRefreshToken) {
      await declineCalendarInvite(meetingId);
      refreshMeetings();
    } else {
      window.open(
        `${
          process.env.NODE_ENV === 'development'
            ? 'http://127.0.0.1:8080'
            : 'https://community.whitecollarprofessional.com'
        }/api/v1/auth/google/calendar`,
        '_blank'
      );
    }
  };

  const deleteMeetingEvent = async (meetingId: any) => {
    await deleteUserMeeting(meetingId);
    refreshMeetings();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (meeting?._userId) {
        const clientUser = await getUserDetails(meeting._userId);
        setClient(clientUser || null);
      }
      if (meeting?.userId) {
        const professionalUser = await getUserDetails(meeting.userId);
        setProfessional(professionalUser || null);
      }
    };

    fetchUsers();
  }, [meeting]);

  return (
    <div className={styles.meetingItemContainer}>
      <div className={styles.meetingWrapper}>
        <div className={styles.meetingIconInfoWrapper}>
          {window?.innerWidth > 700 && (
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faCalendarDays} fontSize={40} />
            </div>
          )}
          <div className={styles.infoContainer}>
            <h4 className={styles.meetingTitle}>{meeting?.title}</h4>
            <div className={styles.professionalInfo}>
              <div
                className={styles.professionalName}
                onClick={() =>
                  router(`/profile?profileId=${professional?._id.toString()}`)
                }
              >
                <FontAwesomeIcon icon={faUser} />
                &nbsp;:&nbsp;{' '}
                {meeting.userId.toString() === loggedInUserId
                  ? client?.name.split(' ')[0]
                  : professional?.name.split(' ')[0]}
              </div>
              <div className={styles.meetingTime}>
                <FontAwesomeIcon icon={faClock} /> &nbsp;:&nbsp;
                {formatDateTime(meeting.startTime)}
              </div>
            </div>
          </div>
        </div>

        {window?.innerWidth > 700 && (
          <div className={styles.actionsContainer}>
            {meeting?.userId.toString() === loggedInUserId &&
              meeting?.isApproved === '' && (
                <div className={styles.clientActions}>
                  <button
                    className={styles.acceptButton}
                    onClick={() => handleAcceptInvite(meeting?._id)}
                  >
                    Accept
                  </button>
                  <button
                    className={styles.declineButton}
                    onClick={() => handleDeclineInvite(meeting?._id)}
                  >
                    Decline
                  </button>
                </div>
              )}
            {meeting?.userId.toString() === loggedInUserId &&
              meeting?.isApproved === 'approved' && (
                <a href={meeting?.meetLink} target="_blank">
                  <button
                    className={styles.joinButton}
                    disabled={!meeting?.isApproved}
                  >
                    Click Here to Join
                  </button>
                </a>
              )}
            {meeting?.userId.toString() === loggedInUserId &&
              meeting?.isApproved === 'declined' && (
                <a href={meeting?.meetLink} target="_blank">
                  <Button
                    style={{
                      background: '#e1e1e1',
                      border: '2px solid #e1e1e1',
                      cursor: 'not-allowed',
                      color: '#000',
                    }}
                    className={styles.joinButton}
                    disabled={meeting?.isApproved === 'declined'}
                  >
                    Meeting Declined
                  </Button>
                </a>
              )}

            {meeting?._userId.toString() === loggedInUserId && (
              <a href={meeting?.meetLink} target="_blank">
                <button
                  className={styles.joinButton}
                  disabled={meeting?.isApproved === 'declined'}
                >
                  Click Here to Join
                </button>
              </a>
            )}

            {meeting.userId === loggedInUserId &&
              meeting?.isApproved !== '' && (
                <div>
                  <Dropdown
                    isOpen={openMeetActions}
                    toggle={() => setOpenMeetActions(!openMeetActions)}
                    className={styles.meetingActionsDropdown}
                  >
                    <DropdownToggle
                      style={{ background: 'transparent', border: 'none' }}
                      onClick={() => {
                        setOpenMeetActions(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsisVertical} color="#333" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {meeting?.userId.toString() === loggedInUserId &&
                        meeting?.isApproved === 'declined' && (
                          <DropdownItem
                            onClick={() => {
                              handleAcceptInvite(meeting?._id);
                            }}
                          >
                            Accept
                          </DropdownItem>
                        )}
                      {meeting?.userId.toString() === loggedInUserId &&
                        meeting?.isApproved === 'approved' && (
                          <DropdownItem
                            onClick={() => {
                              handleDeclineInvite(meeting?._id);
                            }}
                          >
                            Decline
                          </DropdownItem>
                        )}
                      {meeting?._userId.toString() === loggedInUserId && (
                        <DropdownItem
                          onClick={() => {
                            deleteMeetingEvent(meeting?._id);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            {meeting._userId === loggedInUserId && (
              <div>
                <Dropdown
                  isOpen={openMeetActions}
                  toggle={() => setOpenMeetActions(!openMeetActions)}
                  className={styles.meetingActionsDropdown}
                >
                  <DropdownToggle
                    style={{ background: 'transparent', border: 'none' }}
                    onClick={() => {
                      setOpenMeetActions(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} color="#333" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {meeting?.userId.toString() === loggedInUserId &&
                      meeting?.isApproved === 'declined' && (
                        <DropdownItem
                          onClick={() => {
                            handleAcceptInvite(meeting?._id);
                          }}
                        >
                          Accept
                        </DropdownItem>
                      )}
                    {meeting?.userId.toString() === loggedInUserId &&
                      meeting?.isApproved === 'approved' && (
                        <DropdownItem
                          onClick={() => {
                            handleDeclineInvite(meeting?._id);
                          }}
                        >
                          Decline
                        </DropdownItem>
                      )}
                    {meeting?._userId.toString() === loggedInUserId && (
                      <DropdownItem
                        onClick={() => {
                          deleteMeetingEvent(meeting?._id);
                          refreshMeetings();
                        }}
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.meetingDescription}>{meeting?.description}</div>
      {window?.innerWidth <= 700 && (
        <div className={styles.actionsContainer}>
          {meeting?.userId.toString() === loggedInUserId &&
            meeting?.isApproved === '' && (
              <div className={styles.clientActions}>
                <button
                  className={styles.acceptButton}
                  onClick={() => handleAcceptInvite(meeting?._id)}
                >
                  Accept
                </button>
                <button
                  className={styles.declineButton}
                  onClick={() => handleDeclineInvite(meeting?._id)}
                >
                  Decline
                </button>
              </div>
            )}
          {meeting?.userId.toString() === loggedInUserId &&
            meeting?.isApproved === 'approved' && (
              <a href={meeting?.meetLink} target="_blank">
                <button
                  className={styles.joinButton}
                  disabled={!meeting?.isApproved}
                >
                  Click Here to Join
                </button>
              </a>
            )}
          {meeting?.userId.toString() === loggedInUserId &&
            meeting?.isApproved === 'declined' && (
              <a href={meeting?.meetLink} target="_blank">
                <Button
                  style={{
                    background: '#e1e1e1',
                    border: '2px solid #e1e1e1',
                    cursor: 'not-allowed',
                    color: '#000',
                  }}
                  className={styles.joinButton}
                  disabled={meeting?.isApproved === 'declined'}
                >
                  Meeting Declined
                </Button>
              </a>
            )}

          {meeting?._userId.toString() === loggedInUserId && (
            <a href={meeting?.meetLink} target="_blank">
              <button
                className={styles.joinButton}
                disabled={meeting?.isApproved === 'declined'}
              >
                Click Here to Join
              </button>
            </a>
          )}

          {meeting.userId === loggedInUserId && meeting?.isApproved !== '' && (
            <div>
              <Dropdown
                isOpen={openMeetActions}
                toggle={() => setOpenMeetActions(!openMeetActions)}
                className={styles.meetingActionsDropdown}
              >
                <DropdownToggle
                  style={{ background: 'transparent', border: 'none' }}
                  onClick={() => {
                    setOpenMeetActions(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} color="#333" />
                </DropdownToggle>
                <DropdownMenu>
                  {meeting?.userId.toString() === loggedInUserId &&
                    meeting?.isApproved === 'declined' && (
                      <DropdownItem
                        onClick={() => {
                          handleAcceptInvite(meeting?._id);
                        }}
                      >
                        Accept
                      </DropdownItem>
                    )}
                  {meeting?.userId.toString() === loggedInUserId &&
                    meeting?.isApproved === 'approved' && (
                      <DropdownItem
                        onClick={() => {
                          handleDeclineInvite(meeting?._id);
                        }}
                      >
                        Decline
                      </DropdownItem>
                    )}
                  {meeting?._userId.toString() === loggedInUserId && (
                    <DropdownItem
                      onClick={() => {
                        deleteMeetingEvent(meeting?._id);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
          {meeting._userId === loggedInUserId && (
            <div>
              <Dropdown
                isOpen={openMeetActions}
                toggle={() => setOpenMeetActions(!openMeetActions)}
                className={styles.meetingActionsDropdown}
              >
                <DropdownToggle
                  style={{ background: 'transparent', border: 'none' }}
                  onClick={() => {
                    setOpenMeetActions(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} color="#333" />
                </DropdownToggle>
                <DropdownMenu>
                  {meeting?.userId.toString() === loggedInUserId &&
                    meeting?.isApproved === 'declined' && (
                      <DropdownItem
                        onClick={() => {
                          handleAcceptInvite(meeting?._id);
                        }}
                      >
                        Accept
                      </DropdownItem>
                    )}
                  {meeting?.userId.toString() === loggedInUserId &&
                    meeting?.isApproved === 'approved' && (
                      <DropdownItem
                        onClick={() => {
                          handleDeclineInvite(meeting?._id);
                        }}
                      >
                        Decline
                      </DropdownItem>
                    )}
                  {meeting?._userId.toString() === loggedInUserId && (
                    <DropdownItem
                      onClick={() => {
                        deleteMeetingEvent(meeting?._id);
                        refreshMeetings();
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
