import { Badge, Button } from 'reactstrap';
import classNames from 'classnames';
import styles from './searchList.module.css';
import React, { useContext, useState } from 'react';
import { UserContext } from 'src/context/user.context';
import { IUser } from 'src/services/user-service/user-service.interface';
import {
  deleteFollowUser,
  fetchExperiencedUser,
  followUser,
} from 'src/services/user-service/user-servicec';
import { resolveBEAsset } from 'src/utils/urls';
import { useNavigate } from 'react-router-dom';
import { MessageContext } from 'src/context/message.context';
import SignupModal from 'src/components/stepper/SignupModal';
import { MenuContext } from 'src/context/menu.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBuilding } from '@fortawesome/free-regular-svg-icons';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import WorkForce from './WorkForce';

interface props {
  data: IUser;
  refresh: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  networkPage: boolean;
}
interface UserInfo {
  user: IUser[];
  _userId: string;
  name: string;
  position: string;
  endedOn?: string;
}
const professionMap = {
  ICAI: 'CA',
  ICSI: 'CS',
  ICMAI: 'CWA',
  BCI: 'Adv',
};
const SearchListItem = ({ data, networkPage, refresh, setRefresh }: props) => {
  const loggedInUserId = localStorage.getItem('id');
  const { fetchMessageFromId } = useContext(MessageContext);
  const { menu } = useContext(MenuContext);
  const router = useNavigate();

  const { following, id, addFollowing, removeFollowing } =
    useContext(UserContext);
  const user = JSON.parse(localStorage.getItem('user') || '{}') as IUser;

  const handleFollow = async (e: React.MouseEvent): Promise<void> => {
    e.stopPropagation();
    networkPage && setRefresh(refresh + 1);

    const { data: newFollowing } = await followUser(id, data._id);
    addFollowing(newFollowing);
  };

  const handleUnFollow = async (
    e: React.MouseEvent,
    followingId: string
  ): Promise<void> => {
    e.stopPropagation();

    const { data: newFollowing } = await deleteFollowUser(id, followingId);
    removeFollowing(newFollowing);
  };

  const userFollowings = following?.filter(({ type }: any) => type === 'USER');
  const followingId = userFollowings.find(
    (follow: any) => follow.userId === data?._id
  )?._id;

  const handleMessage = async (e: React.MouseEvent, data: any) => {
    e.stopPropagation();
    await fetchMessageFromId(user._id, data);
  };

  const handleProfile = () => {
    router(`/profile?profileId=${data?._id}`);
  };
  const goToChatWindow = async (data: any) => {
    // await fetchMessageFromId(id, data);
    router(`/chatwindow?userid=${data._id}`);
  };
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);

  const getLatestExperience = (experiences: any) => {
    if (!experiences || experiences.length === 0) {
      return null;
    }

    return experiences.reduce(
      (
        latest: { startedOn: string | number | Date },
        current: { startedOn: string | number | Date }
      ) => {
        return new Date(current.startedOn) > new Date(latest.startedOn)
          ? current
          : latest;
      }
    );
  };
  const latestExperience = getLatestExperience(data?.experiences);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfos, setUserInfos] = useState<UserInfo[]>([]);

  const handleClick = async () => {
    try {
      const response = await fetchExperiencedUser(latestExperience?.name || '');

      if (response && Array.isArray(response.userInfos)) {
        setUserInfos(response.userInfos);
      } else {
        console.error('Unexpected data format:', response);
        setUserInfos([]);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error handling click:', error);
      setUserInfos([]);
    }
  };
  return (
    <>
      <div className={styles['list-item-parent']}>
        <div className={styles['list-item']}>
          <div className={styles['user-dp']}>
            <img
              onClick={() => (!token ? setSignupModal(true) : handleProfile())}
              src={
                data?.picture?.path
                  ? resolveBEAsset(data?.picture?.path || '')
                  : '/images/defaultProfilePic.svg'
              }
              alt="profile"
            />
          </div>
          {data?.isProfessional ? (
            <div className={styles['user-details-container']}>
              <div className={styles['user-name-location']}>
                <div
                  className={styles['user-name']}
                  onClick={() =>
                    !token ? setSignupModal(true) : handleProfile()
                  }
                >
                  <span> {data?.name}</span>&nbsp;
                  {data?.isProfessional && (
                    <img src="/images/prof-verification.png" alt="isVerified" />
                  )}
                </div>
                {data?.city || data?.state ? (
                  <div className={styles['user-location']}>
                    <span>
                      <p>
                        <span /> {data?.city}, {data?.state}
                      </p>
                    </span>
                  </div>
                ) : (
                  <div className={styles['user-location']}>
                    <span>
                      <p>
                        <span /> City, State - N/A
                      </p>
                    </span>
                  </div>
                )}
              </div>
              {((data.memberships || []).length > 0 ||
                latestExperience?.name ||
                latestExperience?.designation) &&
                window.innerWidth > 700 && (
                  <div className={styles['membership-wrapper']}>
                    {data.memberships?.map((membership) => (
                      <Badge
                        key={membership._id}
                        className={classNames(
                          styles['membership-badge'],
                          styles[`color-${membership.name}`]
                        )}
                      >
                        {/** @ts-ignore */}
                        {professionMap[membership.name]}
                      </Badge>
                    ))}{' '}
                    {(latestExperience?.name ||
                      latestExperience?.designation) && (
                      <span
                        onClick={() =>
                          !token ? setSignupModal(true) : handleClick()
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          style={{
                            backgroundColor: '#1a1a1a',
                            borderRadius: '50%',
                            display: 'inline-block',
                            height: '4px',
                            margin: '0 6px 3px',
                            width: '4px',
                            minWidth: '4px',
                          }}
                        />{' '}
                        <FontAwesomeIcon icon={faBuilding} />
                        &nbsp; &nbsp;
                        <span
                          style={{
                            borderBottom: '1px dotted',
                          }}
                        >
                          {latestExperience?.name} -{' '}
                          {latestExperience?.designation}
                        </span>
                      </span>
                    )}
                  </div>
                )}
            </div>
          ) : (
            <>
              {(data?.name || data?.city || data?.state) && (
                <div className={styles['user-name-location-nonProf']}>
                  {data?.name && (
                    <div
                      className={styles['user-name']}
                      onClick={() =>
                        !token ? setSignupModal(true) : handleProfile()
                      }
                    >
                      <span> {data?.name}</span>&nbsp;
                      {data?.isProfessional && (
                        <img
                          src="/images/prof-verification.png"
                          alt="isVerified"
                        />
                      )}
                    </div>
                  )}
                  {(data?.city || data?.state) && (
                    <div className={styles['user-location']}>
                      <span>
                        <p>
                          <span /> {data?.city}, {data?.state}
                        </p>
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {data?.isProfessional && data?._id !== loggedInUserId && (
            <div className={styles['item-action']}>
              {window.innerWidth > 700 ? (
                <Button
                  color="transparent"
                  onClick={(e) => handleMessage(e, data)}
                  className={styles['item-action-btn']}
                  disabled={!token}
                >
                  Message
                </Button>
              ) : (
                <Button
                  color="transparent"
                  onClick={(e) => goToChatWindow(data)}
                  className={styles['item-action-btn']}
                  disabled={!token}
                >
                  Message
                </Button>
              )}

              {followingId ? (
                <Button
                  color="transparent"
                  className={classNames(
                    styles['item-action-btn'],
                    styles['selected']
                  )}
                  onClick={(e) =>
                    handleUnFollow(e, (followingId || '').toString())
                  }
                  disabled={!token}
                >
                  Unfollow
                </Button>
              ) : (
                <Button
                  color="transparent"
                  className={styles['item-action-btn']}
                  onClick={(e) => handleFollow(e)}
                  disabled={!token}
                >
                  Follow
                </Button>
              )}
            </div>
          )}
        </div>

        {(data.memberships || data.expertise) && (
          <div className={styles['membership-expertise']}>
            {(data.memberships || []).length > 0 && window.innerWidth < 700 && (
              <div className={styles['membership-wrapper']}>
                {data.memberships?.map((membership) => (
                  <Badge
                    key={membership._id}
                    className={classNames(
                      styles['membership-badge'],
                      styles[`color-${membership.name}`]
                    )}
                  >
                    {/** @ts-ignore */}
                    {professionMap[membership.name]}
                  </Badge>
                ))}{' '}
                {(latestExperience?.name || latestExperience?.designation) && (
                  <>
                    <span
                      style={{
                        backgroundColor: '#1a1a1a',
                        borderRadius: '50%',
                        display: 'inline-block',
                        height: '4px',
                        margin: '0 6px 2px',
                        width: '4px',
                        minWidth: '4px',
                      }}
                    />{' '}
                    <FontAwesomeIcon icon={faBuilding} />
                    &nbsp;
                    <span
                      onClick={handleClick}
                      style={{
                        cursor: 'pointer',
                        borderBottom: '1px dotted',
                        fontSize: '10px',
                        fontWeight: '500',
                      }}
                    >
                      {latestExperience?.name} - {latestExperience?.designation}
                    </span>{' '}
                  </>
                )}
              </div>
            )}
            {data?.expertise && (
              <div className={styles['user-expertise']}>
                {data?.expertise
                  ?.slice(0, window.innerWidth > 700 ? 3 : 2)
                  .map((expertise, idx) => (
                    <div key={idx}>
                      <span
                        style={{
                          cursor: 'pointer',
                          fontSize: `${
                            window.innerWidth < 700 ? '10px' : '12px'
                          }`,
                        }}
                        onClick={() =>
                          !token ? setSignupModal(true) : handleProfile()
                        }
                      >
                        {
                          menu.find((item) => item.id === expertise.metaDataId)
                            ?.name
                        }
                      </span>
                      {idx !== (window.innerWidth > 700 ? 2 : 1) && (
                        <>
                          <span>, </span>
                        </>
                      )}
                    </div>
                  ))}
                {(window.innerWidth > 700
                  ? data?.expertise?.length > 3
                  : data?.expertise?.length > 2) && (
                  <div
                    style={{ marginLeft: '2px', cursor: 'pointer' }}
                    onClick={() =>
                      !token ? setSignupModal(true) : handleProfile()
                    }
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{
                          color: '#4b619b',
                          fontSize: '8px',
                          marginBottom: '1px',
                        }}
                      />
                      <span
                        style={{
                          color: '#4b619b',
                          fontSize: '10px',
                        }}
                      >
                        {data.expertise.length -
                          (window.innerWidth > 700 ? 3 : 2)}
                        &nbsp;more
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <WorkForce
          userInfos={userInfos}
          orgName={latestExperience.name}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default SearchListItem;
