import { useEffect, useMemo, useState } from 'react';
import SearchList from 'src/components/search/searchList';
import Layout from 'src/layouts/layout';
import Modal from 'react-bootstrap/Modal';

import { useLocation } from 'react-router-dom';
import { SEARCH_TYPE } from 'src/config/enums';
// import styles from './search.module.css';
import styles from '../../components/search/searchList/searchList.module.css';
import DashboardList from 'src/components/dashboard/dashbaordList';
import classNames from 'classnames';
import ExpertiseFilterProfessional from 'src/components/search/searchList/expertiseFilter';
import { Button } from 'reactstrap';

const Search = () => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let variables = useQuery();
  const searchType = variables.get('searchType');

  //new
  const [isCategory, setIsCategory] = useState(false);
  const [isSlidingIn, setIsSlidingIn] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  const showCategory = () => {
    setIsCategory(true);
  };
  const handleClose = () => {
    setIsSlidingIn(false);
    setTimeout(() => setIsCategory(false), 300);
  };
  useEffect(() => {
    if (isCategory) {
      setIsSlidingIn(true);
      setIsSlidingOut(false);
    } else if (!isCategory && isSlidingIn) {
      setIsSlidingOut(true);
      setTimeout(() => setIsCategory(false), 300); // Wait for the sliding-out animation to complete
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategory]);
  return (
    <Layout contentBordered>
      <div className={styles['wc-dashboard']}>
        <>
          {searchType === SEARCH_TYPE.PROFESSIONAL ? (
            <>
              <SearchList />
              {window.innerWidth <= 700 && (
                <div>
                  <Button
                    onClick={() => {
                      showCategory();
                    }}
                    className={styles.userFilterButton}
                  >
                    <img
                      src="/images/filterIcon.png"
                      alt="filterIcon"
                      height={24}
                    />
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <DashboardList />
            </>
          )}
        </>
      </div>
      {isCategory && window.innerWidth < 700 && (
        <Modal
          show={isCategory}
          onHide={handleClose}
          className={classNames(
            isSlidingIn
              ? styles['userFilterModalOpen']
              : isSlidingOut
              ? styles['userFilterModalClose']
              : styles['userFilterModal']
          )}
        >
          <ExpertiseFilterProfessional />
        </Modal>
      )}
    </Layout>
  );
};

export default Search;
