import axios from 'axios';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './callback.module.css';
import axiosInstance from 'src/config/api';

export default function Redirect() {
  const [searchParams] = useSearchParams();
  const userId = localStorage.getItem('id') || '';

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      const getToken = async () => {
        try {
          await axiosInstance.post(
            `${
              process.env.NODE_ENV === 'development'
                ? 'http://127.0.0.1:8080'
                : 'https://community.whitecollarprofessional.com'
            }/api/v1/auth/google/redirect`,
            { code, userId }
          );
        } catch (error) {
          console.error('Error fetching token:', error);
        } finally {
          window.close();
        }
      };

      getToken();
    }
  }, [searchParams]);

  return (
    <div>
      <div className={styles['preloader-plus']}>
        <img
          src="/images/wc_logoMain.svg"
          className={styles['preloader-custom-img']}
          alt="wcp-logo"
        />
      </div>
    </div>
  );
}
