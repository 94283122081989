import axiosInstance from 'src/config/api';

export const scheduleMeeting = async (
  title: string,
  description: string,
  startTime: string,
  userId: string,
  isApproved: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/meetings`, {
      title,
      description,
      startTime,
      userId,
      isApproved,
    });
    return response.data;
  } catch (error) {
    console.log('error while creating the user service item', error);
  }
  return;
};

export const fetchUserMeetings = async () => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.get(`${baseUrl}/api/v1/meetings`);
    return response.data;
  } catch (error) {
    console.log('error while fetching user service list', error);
  }
  return;
};

export const deleteUserMeeting = async (meetingId: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.delete(
      `${baseUrl}/api/v1/auth/google/delete-event`,
      {
        data: { meetingId },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while deleting the meeting:', error);
  }
};

export const acceptCalendarInvite = async (meetingId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/auth/google/accept-invite`,
      {
        meetingId,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while accepting the calendar invite:', error);
  }
};

export const declineCalendarInvite = async (meetingId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/auth/google/decline-invite`,
      {
        meetingId,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while accepting the calendar invite:', error);
  }
};

export const addUserAvailabilitySlots = async (
  availability: { day: string; startTime: string; endTime: string }[],
  isAddSlot: boolean
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(
      `${baseUrl}/api/v1/meetings/availability`,
      { availability, isAddSlot }
    );
    return response.data;
  } catch (error) {
    console.log('Error while saving user availability', error);
  }
  return;
};

export const deleteUserMeetingSlot = async (slotId: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.delete(
      `${baseUrl}/api/v1/meetings/slot/${slotId}`
    );
    return response.data;
  } catch (error) {
    console.log('Error while deleting user meeting slot', error);
  }
  return;
};

export const toggleUserMeetingAvailability = async (
  isAvailableForMeetings: boolean
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.patch(
      `${baseUrl}/api/v1/meetings/toggle`,
      { isAvailableForMeetings }
    );
    return response.data;
  } catch (error) {
    console.log('Error while toggling user meeting availability', error);
    return;
  }
};
