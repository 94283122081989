import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import styles from './events.layout.module.css';
import { SubmittedData } from './events-interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Layout from 'src/layouts/layout';
import { IUser } from 'src/services/user-service/user-service.interface';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import BottomBar from '../bottombar/BottomBar';
import { LoadingLottie } from '../lotties/loading';
import SignupModal from '../stepper/SignupModal';
import { Helmet } from 'react-helmet-async';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import classNames from 'classnames';
import CreateEvent from './CreateEvent';
import AppPopUp from '../bottombar/AppPopUp';
import {
  fetchAllEventUpvotes,
  fetchEvents,
} from 'src/services/event-service/event-service';
import EventListItem from './EventListItem';

const Events = () => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newSearch, setNewSearch] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [submittedData, setSubmittedData] = useState<SubmittedData[]>([]);
  const today = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>('');
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [eventsCompleted, setEventsCompleted] = useState<boolean>(false);
  const [eventFilter, setEventFilter] = useState('upcoming');
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('eventId') || '';
  const eventNameSearch = searchParams.get('eventName') || '';
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  const profileId = localStorage.getItem('id') || '';
  const [userDetail, setUserDetail] = useState<IUser | null>(null);

  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setUserDetail(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId);
    }
  }, [profileId]);

  useEffect(() => {
    if (userDetail) {
      localStorage.setItem('user', JSON.stringify(userDetail));
    }
  }, [userDetail]);

  const handleUpcomingFilterChange = () => {
    const urlParams = new URLSearchParams(location.search);
    if (eventId) {
      urlParams.delete('eventId');
    }
    if (eventNameSearch) {
      urlParams.delete('eventName');
    }

    setStartDate(today);
    setEndDate('');
    setEventFilter('upcoming');
    setRefresh(refresh + 1);
    navigate(`/events?${urlParams.toString()}`);
  };

  const handlePastFilterChange = () => {
    const urlParams = new URLSearchParams(location.search);
    if (eventId) {
      urlParams.delete('eventId');
    }
    if (eventNameSearch) {
      urlParams.delete('eventName');
    }
    navigate(`/events?${urlParams.toString()}`);
    setStartDate('');
    setEndDate(today);
    setEventFilter('past');
    setRefresh(refresh + 1);
  };

  const handleLoadMore = () => {
    if (!token) {
      setSignupModal(true);
    } else if (!eventsCompleted) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const getEvents = async () => {
    setLoading(true);

    try {
      const res = await fetchEvents(
        pageNumber,
        startDate,
        endDate,
        eventId,
        eventNameSearch
      );

      const formattedEventsData: SubmittedData[] = res?.data.map((event) => ({
        id: event._id,
        eventName: event?.title,
        joiningUrl: event?.link,
        eventDesc: event?.content,
        eventImage: event?.attachments[0]?.path,
        startDateTime: event?.startedOn,
        endDateTime: event?.endedOn,
        upvoteCount: event?.upvoteCount,
        userId: event?._userId as unknown as string,
        cpeCredit: event?.cpeCredit,
        eventType: event?.eventType,
        postUpvotes: event?.postUpvotes,
      }));

      if (!formattedEventsData?.length) {
        setEventsCompleted(true);
      }

      if (formattedEventsData.length) {
        const eventIds = formattedEventsData
          .map((event) => event.id)
          .filter(Boolean);

        try {
          const response = await fetchAllEventUpvotes(eventIds);
          const upvotesMap = response.data.data;

          const updatedEventList = formattedEventsData.map((event) => ({
            ...event,
            postUpvotes: upvotesMap[event.id] || [],
          }));

          const finalEventList = updatedEventList.map((event) => ({
            ...event,
            interested: event?.postUpvotes.some(
              (item: any) => item?._userId === profileId
            ),
          }));
          if (pageNumber === 1) {
            setSubmittedData(finalEventList);
          } else if (pageNumber > 1) {
            setSubmittedData([...submittedData, ...finalEventList]);
          }
        } catch (upvoteError) {
          console.error('Error fetching event upvotes:', upvoteError);
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
      setNewSearch(false);
    }
  };

  useEffect(() => {
    if (eventsCompleted) {
      return;
    }
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dummyTrigger, eventId, eventNameSearch]); // Added eventId and eventNameSearch

  useEffect(() => {
    setPageNumber(1);
    setEventsCompleted(false);
    setSubmittedData([]);
    setNewSearch(true);
    setDummyTrigger(dummyTrigger + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, eventNameSearch]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (eventId || eventNameSearch) {
      setEventFilter('All');
      setRefresh(refresh + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, eventNameSearch]);

  useEffect(() => {
    if (eventId || eventNameSearch) {
      return;
    }
    if (eventFilter === 'upcoming') {
      handleUpcomingFilterChange();
    }
    if (eventFilter === 'past') {
      handlePastFilterChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilter]);

  return (
    <>
      <Helmet>
        <title>White Collar Professional | Events</title>
        <meta
          name="description"
          content="Curated list of webinars, workshops, and networking opportunities, to upskill, explore new topics, and expand their networks."
        />
        <meta
          name="keywords"
          content="WCP, White Collar Professional, Chartered accountant, Company secretary, Cost accountant, Advocate, Lawyer, Legal, Events, Shows, Professional events, Webinar, Workshops, Networking, Career growth, Stay connected"
        />

        <link rel="canonical" href="/events" />
      </Helmet>
      <Layout contentBordered>
        <div>
          <div className={styles['header-wrapper']}>
            <div className={styles['event-header-hr']} />
            <div className={styles['event-header-label']}>Show results for</div>
            <div className={styles['event-filter-wrapper']}>
              <Form.Group controlId="filterUpcoming">
                <div className={styles['events-filter']}>
                  <Button
                    onClick={handleUpcomingFilterChange}
                    className={classNames(
                      styles['event-filter-btn'],
                      eventFilter === 'upcoming'
                        ? styles['event-filter-active']
                        : ''
                    )}
                  >
                    {window.innerWidth >= 700 ? 'Upcoming Events' : 'Upcoming'}
                  </Button>
                </div>
              </Form.Group>
              <Form.Group controlId="filterPast">
                <div className={styles['events-filter']}>
                  <Button
                    onClick={handlePastFilterChange}
                    className={classNames(
                      styles['event-filter-btn'],
                      eventFilter === 'past'
                        ? styles['event-filter-active']
                        : ''
                    )}
                  >
                    {window.innerWidth >= 700 ? 'Past Events' : 'Past'}
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
          {window.innerWidth < 700 && <CreateEvent />}
          <div className={styles['all-events-container']}>
            {submittedData.map((data) => (
              <EventListItem data={data} />
            ))}
          </div>

          {eventId ? null : loading ? (
            <LoadingLottie height="150px" />
          ) : (
            submittedData && (
              <div className="text-center">
                <div className={styles['load-more']}>
                  {eventsCompleted ? (
                    <>
                      {submittedData.length === 0
                        ? 'There are No Events to display'
                        : "You're all caught up!"}
                      &nbsp;
                      {window.scrollY !== 0 && (
                        <Button
                          className={styles['topScroll']}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Go to Top
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      color="transparent"
                      className={styles.loadMoreBtn}
                      onClick={handleLoadMore}
                    >
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ color: '#1a1a1a' }}
                      />
                    </Button>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        {window.innerWidth < 700 && <AppPopUp />}

        <div className={styles['event-bottombar']}>
          <BottomBar />
        </div>
        <SignupModal
          signupModal={signupModal}
          setSignupModal={setSignupModal}
        />
      </Layout>
    </>
  );
};

export default Events;
