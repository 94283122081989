import React, { useState } from 'react';
import AvatarRow from '../avatar-row/avatar-row';
import { ITrend } from 'src/services/trend-service/trend-service.interface';
import { formatDistanceToNow, isValid } from 'date-fns';
import { resolveBEAsset } from 'src/utils/urls';
import SignupModal from '../stepper/SignupModal';
import {
  deleteTrendUpvote,
  trendUpvote,
} from 'src/services/trend-service/trend-service';
import { IUser } from 'src/services/user-service/user-service.interface';
import TrendCommentView from './TrendCommentView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import {
  faCaretUp,
  faCheck,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import styles from './trend.module.css';

import TrendActions from './TrendActions';

interface TrendViewProps {
  data: ITrend;
}

const TrendView: React.FC<TrendViewProps> = ({ data }) => {
  const [trendData, setTrendData] = useState<ITrend>(data);
  const [signupModal, setSignupModal] = useState(false);
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');
  const userRole = localStorage.getItem('role');
  const self = id === data?._userId.id;
  const [showComments, setShowComment] = useState(false);
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const getLapsedTime = (createdOn: string) => {
    const createdOnDate = new Date(createdOn);
    if (isValid(createdOnDate)) {
      return formatDistanceToNow(createdOnDate, { addSuffix: true });
    } else {
      return 'Invalid date';
    }
  };

  const handleUpvote = async () => {
    setLoadingUpvotes(true);
    if (!token) {
      setSignupModal(true);
      setLoadingUpvotes(false);
      return;
    }

    if (trendData.interested) {
      try {
        await deleteTrendUpvote(trendData._id, id!);
        setTrendData((prev) => ({
          ...prev,
          interested: false,
          upvoteCount: Math.max(0, prev.upvoteCount - 1),
        }));
      } catch (error) {
        console.error('Error removing upvote:', error);
      }
    } else {
      try {
        await trendUpvote(trendData._id);
        setTrendData((prev) => ({
          ...prev,
          interested: true,
          upvoteCount: prev.upvoteCount + 1,
        }));
      } catch (error) {
        console.error('Error adding upvote:', error);
      }
    }
    setLoadingUpvotes(false);
  };

  const handleCopyTrend = (data: any) => {
    setIsCopying(true);
    navigator.clipboard.writeText(
      `https://community.whitecollarprofessional.com/trending?trendId=${data?._id}`
    );
    setTimeout(() => {
      setIsCopying(false);
    }, 1000);
  };

  const handleToggleContent = () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  };
  const showMoreContent = (content: string) => {
    if (!isExpanded && content.length > 340) {
      setIsExpanded(true);
    } else {
      return;
    }
  };

  return (
    <>
      {trendData && (
        <div className={styles['trend-view-container']}>
          <div className={styles['trend-view-header']}>
            <AvatarRow
              showMembership
              data={trendData._userId as unknown as IUser}
            />
            <div className={styles['trend-view-timestamp']}>
              <>
                {window.innerWidth > 700 && (
                  <>
                    {' '}
                    <span className={styles['time-dot']} />
                    &nbsp; &nbsp;
                  </>
                )}
                {getLapsedTime(trendData.createdOn)}
              </>
            </div>
          </div>

          <div className={styles['trend-view-content']}>
            <div className={styles['trend-view-content-text-container']}>
              <p
                onClick={() => showMoreContent(trendData?.content)}
                dangerouslySetInnerHTML={{ __html: trendData.content }}
                className={`${styles['trend-view-content-text']} ${
                  !isExpanded ? styles['clamped'] : ''
                }
                  ${
                    !isExpanded && trendData.content.length > 340
                      ? styles['contentPointer']
                      : ''
                  }`}
              />

              <p className={styles['read-more']} onClick={handleToggleContent}>
                {isExpanded && trendData.content.length > 340 && (
                  <>
                    Show Less &nbsp;{' '}
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginTop: '2px' }}
                    />
                  </>
                )}
              </p>
            </div>
            {trendData.attachments &&
              trendData.attachments.length > 0 &&
              trendData.attachments
                .filter((item) => item.mimetype.startsWith('image'))
                .map((attachment) => (
                  <div key={attachment._id}>
                    <img
                      src={resolveBEAsset(attachment.path)}
                      alt={attachment.originalName}
                      className={styles['trend-view-attachment']}
                    />
                  </div>
                ))}

            {trendData.attachments &&
              trendData.attachments.length > 0 &&
              trendData.attachments
                .filter((item) => !item.mimetype.startsWith('image'))
                .map((attachment) => (
                  <div
                    key={attachment._id}
                    className={styles.pdfAttachmentWrapper}
                  >
                    <img
                      src="/images/pdfFile.png"
                      alt={attachment.originalName}
                      className={styles['attachmentIcon']}
                    />
                    <div className={styles['attachment-subtitle']}>
                      {attachment.originalName.length > 18
                        ? `${attachment.originalName.slice(0, 18) + '...pdf'}`
                        : attachment.originalName}
                    </div>
                    <Button
                      color="transparent"
                      onClick={() => {
                        window.open(
                          resolveBEAsset(attachment?.path || ''),
                          '_blank'
                        );
                      }}
                      className={styles.pdfDownloadBtn}
                    >
                      View/Download
                    </Button>
                  </div>
                ))}

            <div className={styles['trend-view-interactions']}>
              <Button
                className={styles['trend-comment-btn']}
                color="transparent"
                onClick={() => {
                  !token
                    ? setSignupModal(true)
                    : setShowComment((prev) => !prev);
                }}
              >
                <img src="/images/commentIcon.png" alt="" />
                &nbsp;Comment&nbsp;({trendData.trendCommentCount})
              </Button>
              <Button
                className={styles['trend-view-btn']}
                onClick={handleUpvote}
                color="transparent"
              >
                <img
                  src={
                    trendData.interested
                      ? '/images/upvotedIcon.png'
                      : '/images/upvoteIcon.png'
                  }
                  alt=""
                />
                &nbsp; Like ({trendData.upvoteCount})
                {loadingUpvotes && (
                  <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                )}
              </Button>
              <div style={{ margin: '0 10px 0 auto' }}>
                {!isCopying ? (
                  <FontAwesomeIcon
                    icon={faCopy}
                    onClick={() => handleCopyTrend(trendData)}
                    className={styles['trend-view-copy-icon']}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles['trend-view-copy-icon']}
                  />
                )}
              </div>
              {(userRole === 'SUPER_ADMIN' || self) && (
                <TrendActions
                  trendId={data?._id}
                  type="trend"
                  user={trendData?._userId}
                  initialContent={trendData?.content}
                />
              )}
            </div>
          </div>
          {showComments && (
            <>
              <TrendCommentView data={data} />
            </>
          )}
        </div>
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default TrendView;
