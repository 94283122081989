import React, { useEffect, useState } from 'react';
import { getCommentAnswers } from 'src/services/post-service/post-service';
import {
  IComment,
  IPost,
} from 'src/services/post-service/post-service.interface';
import styles from '../../../pages/question/question.module.css';

import CreatePost from 'src/components/create-post';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CommentListItem from './commentListItem';

const CommentsList = ({
  post,
  updatePost,
}: {
  post: IPost;
  updatePost: (value: any) => void;
}) => {
  const [currentPost, setCurrentPost] = useState<IPost>();
  const [commentList, setCommentList] = useState<IComment[] | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isWork = currentPost?.type === 'WORK';
  const [show, setShow] = useState(false);
  const [editComment, setEditComment] = useState<IComment | null>(null);

  const fetchPostComment = async () => {
    try {
      if (currentPost?._id) {
        const res = (await getCommentAnswers(currentPost._id, 3, pageNumber))
          .data;
        if (pageNumber === 1) {
          setCommentList(res);
        } else {
          setCommentList((prev: any) => [...prev, ...res]);
        }
        setHasMore(res?.length === 3); // If less than pageSize, no more data
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  useEffect(() => {
    setCurrentPost(post);
  }, [post]);

  useEffect(() => {
    fetchPostComment();
  }, [currentPost]);

  useEffect(() => {
    fetchPostComment();
  }, [pageNumber]);

  return (
    <div>
      {commentList?.map((comment: any) => (
        <CommentListItem
          currentPost={currentPost}
          currentComment={comment}
          onDelete={(commentId) => {
            setCommentList((prevRepComment) => {
              return [
                ...(prevRepComment || []).filter(
                  ({ _id }) => _id !== commentId
                ),
              ];
            });
            fetchPostComment();
            updatePost(currentPost?._id);
          }}
          onEdit={(comment) => {
            setShow(true);
            setEditComment(() => comment);
          }}
        />
      ))}
      {hasMore && (
        <div className="text-center">
          {' '}
          <Button
            color="transparent"
            className={styles.loadMoreBtn}
            onClick={() => setPageNumber((prevPage) => prevPage + 1)}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ color: '#1a1a1a' }}
            />
          </Button>
        </div>
      )}
      {show && (
        <CreatePost
          //@ts-ignore
          initialCommentData={editComment}
          onCreateComment={(comment) => {
            fetchPostComment();
          }}
          onUpdatePost={() => {}}
          onUpdateComment={() => {
            fetchPostComment();
          }}
          show={show}
          onHide={() => {
            setShow(() => false);
          }}
          isAnswer={!isWork}
          isComment={isWork}
          ques={currentPost}
        />
      )}
    </div>
  );
};

export default CommentsList;
