import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import AttachmentList from 'src/components/attachment-list/attachment-list';
import AvatarRow from 'src/components/avatar-row/avatar-row';
import { UserContext } from 'src/context/user.context';
import {
  deleteCommentReply,
  deleteCommentReplyUpvote,
  postCommentReplyUpvote,
} from 'src/services/post-service/post-service';
import {
  IComment,
  IPost,
  IReply,
} from 'src/services/post-service/post-service.interface';
import { formatDateStr } from 'src/utils/dateUtil';
import styles from './question.module.css';

type IProps = {
  reply: IReply;
  selectedQues: IPost;
  comment: IComment;
  onDelete: (replyId: string) => void;
  onEdit: (replyId: IReply) => void;
};

const ReplyView = ({
  reply: initialReply,
  selectedQues,
  comment,
  onDelete,
  onEdit,
}: IProps) => {
  const [reply, setReply] = useState(initialReply);
  const { id } = useContext(UserContext);
  const [showReplyMenu, setShowReply] = useState(false);
  const [updating, setUpdating] = useState(false);
  const loggedInUserId = localStorage.getItem('id') || '';
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const self = reply?._userId._id === id;

  const handleDelete = async () => {
    if (selectedQues) {
      setUpdating(true);
      await deleteCommentReply(selectedQues?._id, comment._id, reply._id);
      onDelete(reply._id);
      setUpdating(false);
    }
  };

  useEffect(() => {
    setReply(() => initialReply);
  }, [initialReply]);

  //upvotes:
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);

  const isUpvoted = () =>
    reply?.upvotes?.some(
      (upvote) => upvote?._userId.toString() === loggedInUserId
    );

  const [isVoted, setIsVoted] = useState(isUpvoted);
  const addUpvote = async () => {
    if (selectedQues) {
      setLoadingUpvotes(true);
      try {
        const upvote = await postCommentReplyUpvote(
          selectedQues._id,
          comment._id,
          reply._id
        );

        setReply((prevReply: any) => ({
          ...prevReply,
          upvotes: prevReply?.upvotes?.concat(upvote),
          upvoteCount: (prevReply.upvoteCount || 0) + 1,
        }));
      } catch (error) {
        console.error('error in adding upvote');
      }

      setLoadingUpvotes(false);
    }
  };

  const removeUpvote = async (userId: string) => {
    if (selectedQues) {
      setLoadingUpvotes(true);

      try {
        await deleteCommentReplyUpvote(
          selectedQues._id,
          comment._id,
          reply._id,
          userId
        );

        setReply((prevReply) => ({
          ...prevReply,
          upvotes: prevReply?.upvotes?.filter(
            (upvote) => upvote._userId.toString() !== loggedInUserId
          ),
          upvoteCount: prevReply.upvoteCount ? prevReply.upvoteCount - 1 : 0,
        }));
      } catch (error) {
        console.error('error in adding upvote');
      }
      setLoadingUpvotes(false);
    }
  };

  useEffect(() => {
    setIsVoted(() => isUpvoted());
  }, [reply?.upvotes]);

  return (
    <>
      <div className={styles['replies-container']} key={reply._id}>
        <div className={styles['result-avatar']}>
          <AvatarRow showMembership data={reply._userId} />
          <span className={styles['reply-header-date']}>
            {formatDateStr(reply.createdOn)}
          </span>
        </div>
        <div className={styles['reply-content']}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.questionContent} style={{ width: '95%' }}>
              {reply.content}
            </div>
            {selectedQues?.type === 'WORK' && (
              <div
                className={styles['item-interaction-right']}
                style={{ justifyContent: 'flex-end' }}
              >
                {(self ||
                  ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(user.role)) && (
                  <>
                    <Dropdown
                      className={styles.menuButton}
                      isOpen={showReplyMenu}
                      toggle={() => !updating && setShowReply((old) => !old)}
                      direction="down"
                    >
                      <DropdownToggle>
                        {updating ? (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin={true}
                            size="sm"
                            className={styles['item-iteraction-toggle']}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            size="sm"
                            className={styles['item-iteraction-toggle']}
                          />
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => onEdit(reply)}>
                          Edit
                        </DropdownItem>
                        <DropdownItem onClick={handleDelete}>
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}
              </div>
            )}
          </div>
          {reply?.attachments?.length > 0 && (
            <AttachmentList attachments={reply.attachments} />
          )}
          {selectedQues?.type !== 'WORK' && (
            <>
              <hr className={styles['horizontal-ruler-reply']} />
              <div className={styles['item-interaction-reply']}>
                <div className={styles['item-interaction-left']}>
                  {selectedQues?.type !== 'WORK' && (
                    <Button
                      className={styles['interaction-btn']}
                      color="transparent"
                      onClick={
                        isVoted ? () => removeUpvote(loggedInUserId) : addUpvote
                      }
                    >
                      <img
                        src={
                          isVoted
                            ? '/images/upvotedIcon.png'
                            : '/images/upvoteIcon.png'
                        }
                        alt="replyUpvoteIcon"
                      />
                      &nbsp;Upvote ({reply.upvoteCount || 0})
                      {loadingUpvotes && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin={true}
                          size="sm"
                        />
                      )}
                    </Button>
                  )}
                </div>
                <div className={styles['item-interaction-right']}>
                  {(self ||
                    ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(
                      user.role
                    )) && (
                    <>
                      <Dropdown
                        className={styles.menuButton}
                        isOpen={showReplyMenu}
                        toggle={() => !updating && setShowReply((old) => !old)}
                        direction="down"
                      >
                        <DropdownToggle>
                          {updating ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              size="sm"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          )}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => onEdit(reply)}>
                            Edit
                          </DropdownItem>{' '}
                          <DropdownItem onClick={handleDelete}>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReplyView;
