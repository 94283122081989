import axiosInstance from 'src/config/api';
import { IPost, Pin } from '../post-service/post-service.interface';
import { INotificationResponse } from '../notification-service/notification-interface';
import { IUser } from '../user-service/user-service.interface';

export const getUpdates = async (
  pageNumber: number,
  pageSize: number,
  startDate: string,
  endDate: string,
  user: IUser,
  notiPriority: string,
  view?: string,
  metaDataId?: string,
  subMetaDataId?: string,
  notiTitle?: string,
  updateId?: string
): Promise<INotificationResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const payload: any = {
    sortBy: 'publishedOn',
    sortOrder: 'desc',
    pageNumber,
    pageSize,
  };

  if (startDate) {
    payload.publishedOnFrom = startDateObj.getTime();
  }
  if (endDate) {
    payload.publishedOnTo = endDateObj.getTime();
  }

  if (metaDataId) {
    payload['expertise.metaDataId'] = metaDataId;
  }

  if (subMetaDataId) {
    payload['expertise.subMetaDataId'] = subMetaDataId;
  }
  if (view === 'pins-noti') {
    payload.view = 'pins-noti';
    payload._userId = user._id;
  }
  if (updateId) {
    payload._id = updateId;
  }
  if (notiTitle) {
    payload.notiTitle = notiTitle;
  }
  if (notiPriority) {
    payload.notiPriority = notiPriority;
  }
  if (notiPriority !== 'high' && !updateId && !notiTitle && !view)
    if (
      !subMetaDataId &&
      !metaDataId &&
      user &&
      user?.member &&
      user.member.length
    ) {
      for (const member of user.member) {
        if (member === 'BCI') {
          payload.isBCI = true;
        }
        if (member === 'ICMAI') {
          payload.isICMAI = true;
        }
        if (member === 'ICSI') {
          payload.isICSI = true;
        }
        if (member === 'ICAI') {
          payload.isICAI = true;
        }
      }
    }

  return (
    await axiosInstance.get(`${baseUrl}/api/v1/update`, {
      params: payload,
    })
  ).data;
};

export const createUpdate = async (
  title: string,
  notiLink: string,
  desc: string,
  selectedOption: any,
  publishedOn?: Date,
  notificationPriority: string = '',
  notificationSource: string = '',
  plainContent?: string
): Promise<IPost> => {
  let res;
  const baseUrl = process.env.REACT_APP_API_HOST;
  try {
    const payload = {
      title,
      notiLink: notiLink,
      content: desc,
      publishedOn,
      notificationPriority,
      notificationSource,
      plainContent,
    };

    res = (await axiosInstance.post(`${baseUrl}/api/v1/update`, payload)).data;

    const metaDataId: Array<string> = [];
    const subMetaDataId: Array<string> = [];

    const removeExpertise: Array<any> = [];
    const existingExpertise: Array<any> = [];
    selectedOption.forEach((option: any) => {
      const data = JSON.parse(option.value);

      if (data.metaDataId) {
        if (
          !existingExpertise?.find(
            (item) => item.metaDataId === data.metaDataId
          )
        ) {
          metaDataId.push(data.metaDataId);
        }
      } else if (data.subMetaDataId) {
        if (
          !existingExpertise?.find(
            (item) => item.subMetaDataId === data.subMetaDataId
          )
        ) {
          subMetaDataId.push(data.subMetaDataId);
        }
      }
    });

    existingExpertise.forEach((expertise: any) => {
      const found = selectedOption.find((option: any) => {
        const data = JSON.parse(option.value);
        if (expertise.metaDataId) {
          return data.metaDataId === expertise.metaDataId;
        } else if (expertise.subMetaDataId) {
          return data.subMetaDataId === expertise.subMetaDataId;
        }
        return false;
      });

      if (!found) {
        removeExpertise.push(expertise._id);
      }
    });

    if (metaDataId.length || subMetaDataId.length) {
      await axiosInstance.post(
        `${baseUrl}/api/v1/update/${res._id}/expertise`,
        {
          metaDataId,
          subMetaDataId,
        }
      );
    }

    return res;
  } catch (e) {
    if (res.id) {
      await axiosInstance.delete(`${baseUrl}/api/v1/post/${res.id}`);
    }
    throw new Error('failed');
  }
};
export const pinUpdate = async (
  userId: string,
  updateId: string,
  type = Pin.UPDATE
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return axiosInstance.post(`${baseUrl}/api/v1/user/${userId}/pin`, {
    type,
    updateId,
  });
};

export const fetchUpdatePin = async (updateId: any, userId: string) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.get(
    `${baseUrl}/api/v1/update/${updateId}/pin/${userId}`
  );
};

export const getUpdatesSearch = async (
  notiTitle?: string
): Promise<INotificationResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  const payload: any = {
    sortBy: 'publishedOn',
    sortOrder: 'desc',
    pageNumber: 1,
    pageSize: 10,
    notiPriority: 'low',
  };

  if (notiTitle) {
    payload.notiTitle = notiTitle;
  }

  return (
    await axiosInstance.get(`${baseUrl}/api/v1/update`, {
      params: payload,
    })
  ).data;
};

export const sendUpdateMemberNotification = async (
  updateId: string
): Promise<IPost> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.post(
    `${baseUrl}/api/v1/update/${updateId}/pushnotification`
  );
};
