import { useContext, useEffect, useState } from 'react';
import Layout from 'src/layouts/layout';
import styles from './notification.module.css';

import {
  IFormattedNotifications,
  formatNotifications,
  margeFormattedNotifications,
} from './notification.helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NoData from 'src/components/no-data';
import { LoadingLottie } from 'src/components/lotties/loading';
import BottomBar from 'src/components/bottombar/BottomBar';
import { Button, Input } from 'reactstrap';
import DashboardCategory from 'src/components/dashboard/dashbaordList/DashboardCategory';
import { MenuContext } from 'src/context/menu.context';
import { IMenuList } from 'src/components/sidebar/sidebar.interface';
import { formatMenu } from 'src/components/sidebar/sidebar.helper';
import SignupModal from 'src/components/stepper/SignupModal';
import { Helmet } from 'react-helmet-async';
import Modal from 'react-bootstrap/Modal';

import PinButton from 'src/components/pin-button';
import { IPost, Pin } from 'src/services/post-service/post-service.interface';
import { UserContext } from 'src/context/user.context';
import AppPopUp from 'src/components/bottombar/AppPopUp';
import { INotification } from 'src/services/notification-service/notification-interface';
import classNames from 'classnames';
import {
  fetchUpdatePin,
  getUpdates,
} from 'src/services/update-service/update-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackwardStep,
  faChevronDown,
  faForwardStep,
} from '@fortawesome/free-solid-svg-icons';

export default function Notification() {
  const [loading, setLoading] = useState(true);
  const [newSearch, setNewSearch] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const metaDataId = searchParams.get('metaDataId') || '';
  const subMetaDataId = searchParams.get('subMetaDataId') || '';
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [refresh, setRefresh] = useState<number>(0);
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const [signupModal, setSignupModal] = useState(false);
  const [formattedNotifications, setFormattedNotifications] =
    useState<IFormattedNotifications>({});
  const [notificationCompleted, setNotificationCompleted] =
    useState<boolean>(false);
  const view = searchParams.get('view') || '';
  const notiId = searchParams.get('notiId') || '';
  const { id } = useContext(UserContext);
  const loggedInUserId = localStorage.getItem('id') || '';
  const notiTitle = searchParams.get('notiName') || '';

  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const [modalData, setModalData] = useState({
    title: '',
    plainContent: '',
    contentUrl: '',
    notificationSource: '',
    userId: '',
    notiObj: {} as INotification,
    pinId: false,
  });

  useEffect(() => {
    if (notificationCompleted) {
      return;
    }
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dummyTrigger, startDate, endDate, notiId]);

  const fetchNotifications = async () => {
    setLoading(true);

    const response = await getUpdates(
      pageNumber,
      100,
      startDate,
      endDate,
      user,
      view === 'pins-noti' ? 'medium' : 'low',
      view,
      metaDataId,
      subMetaDataId,
      notiTitle,
      notiId
    );
    if (!response.data.length) {
      setNotificationCompleted(true);
    }

    const notification = response.data;

    const formatRes = formatNotifications(notification);
    setFormattedNotifications(
      margeFormattedNotifications(formattedNotifications, formatRes)
    );
    setLoading(false);
    setNewSearch(false);
  };

  const currentUrl = window.location.href;

  useEffect(() => {
    setPageNumber(1);
    setNotificationCompleted(false);
    setNewSearch(true);
    setFormattedNotifications({});
    setDummyTrigger((num) => num + 1);
  }, [metaDataId, subMetaDataId, refresh, view, notiTitle, notiId]);

  const [isCategory, setIsCategory] = useState(false);
  const showCategory = () => {
    if (window.innerWidth < 700) {
      window.scrollTo(0, 15);
    }
    setIsCategory(true);
    if (window.innerWidth < 700) {
      window.scrollTo(0, 10);
    }
  };

  const { menu } = useContext(MenuContext);
  const [menuList, setMenuList] = useState<IMenuList[]>([]);

  useEffect(() => {
    if (menu?.length) {
      setMenuList(formatMenu(menu));
    }
  }, [menu]);
  const selectedMetaDataId = searchParams.get('metaDataId');
  const selectedMetaDataTitle = Object.values(menuList).find(
    (obj) => obj.id === selectedMetaDataId
  );
  const title = selectedMetaDataTitle?.title;
  const navigate = useNavigate();

  const handleNotificationClick = async (notiObj: any) => {
    const res = (await fetchUpdatePin(notiObj?._id, loggedInUserId)).data;
    const hasPins = res?.data[0]?._id?.length ? true : false;
    setModalData({
      title: notiObj.title,
      plainContent: notiObj.content,
      contentUrl: notiObj.notiLink,
      notificationSource: notiObj.notificationSource,
      userId: notiObj.userId,
      notiObj: notiObj,
      pinId: hasPins,
    });

    setShowNotificationModal(true);
  };

  const urlParams = new URLSearchParams(searchParams);
  const handlePinNotification = () => {
    if (currentUrl.includes('view=pins-noti')) {
      urlParams.delete('view');
      navigate(`/notification?${urlParams.toString()}`);
    } else {
      urlParams.set('view', 'pins-noti');
      navigate(`/notification?${urlParams.toString()}`);
    }
  };

  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const notificationsArray = Object.keys(formattedNotifications).flatMap(
    (date) => formattedNotifications[date]
  );
  const handleNextNotification = () => {
    if (currentNotificationIndex < notificationsArray.length - 1) {
      const nextNotification = notificationsArray[currentNotificationIndex + 1];
      setModalData({
        title: nextNotification.title,
        plainContent: nextNotification.content,
        // @ts-ignore
        contentUrl: nextNotification.notiLink,
        notificationSource: nextNotification.notificationSource,
        // @ts-ignore

        userId: nextNotification.userId,
        notiObj: nextNotification,
        // @ts-ignore

        pinId: nextNotification.hasPins,
      });
      setCurrentNotificationIndex((index) => index + 1);
    }
  };

  const handlePreviousNotification = () => {
    if (currentNotificationIndex > 0) {
      const prevNotification = notificationsArray[currentNotificationIndex - 1];
      setModalData({
        title: prevNotification.title,
        plainContent: prevNotification.content,
        // @ts-ignore

        contentUrl: prevNotification.notiLink,
        notificationSource: prevNotification.notificationSource,
        // @ts-ignore

        userId: prevNotification.userId,
        notiObj: prevNotification,
        // @ts-ignore

        pinId: prevNotification.hasPins,
      });
      setCurrentNotificationIndex((index) => index - 1);
    }
  };
  return (
    <>
      <Helmet>
        <title>White Collar Professional | Updates</title>
        <meta
          name="description"
          content="Stay updated with latest 1-minute shorts (News, Notifications, and Judgements) in the field of finance, law, legal, tax, audit, stock market, insurance & many more"
        />
        <meta
          name="keywords"
          content="WCP community, White Collar Professional, Chartered accountant, Company secretary, Cost accountant, Advocate, Lawyer,Legal, Updates, News, Notification, Circular, Judgements, High Court, Supreme Court, Memorandum"
        />

        <link rel="canonical" href="/notification" />
      </Helmet>
      <Layout contentBordered>
        <div className={styles['noti-container']}>
          <div className={styles['noti-category']}>
            <div className={styles.notiFilterMobileWrapper}>
              {' '}
              <Button
                className={styles['noti-category-btn']}
                onClick={() => {
                  showCategory();
                }}
              >
                {title === undefined ? 'Filter by Category' : title}
                <span
                  style={{
                    fontSize: '8px',
                    marginLeft: '2px',
                    paddingTop: '2px',
                  }}
                >
                  &#9660;
                </span>
              </Button>
              {currentUrl.includes('/notification?metaDataId') && (
                <img
                  src="/images/close-circle.svg"
                  alt="closeIcon"
                  height={20}
                  onClick={() => {
                    navigate('/notification');
                  }}
                />
              )}
            </div>
            <Button
              onClick={handlePinNotification}
              className={classNames(
                styles['pins-btn'],
                currentUrl.includes('view=pins-noti')
                  ? styles['pins-active']
                  : ''
              )}
            >
              {currentUrl.includes('view=pins-noti') ? (
                <img
                  src={'/images/pinnedIcon.png'}
                  alt="pinIcon - team WCP"
                  height={14}
                  style={{ filter: 'invert(1)' }}
                />
              ) : (
                <img
                  src={'/images/pinnedIcon.png'}
                  alt="pinIcon - team WCP"
                  height={14}
                />
              )}
              &nbsp; Pinned Updates
            </Button>
          </div>
          {isCategory && (
            <DashboardCategory
              isOpen={isCategory}
              setIsOpen={setIsCategory}
              handleCreateSignature
            />
          )}
          <div className={styles['noti-filters']}>
            {window.innerWidth > 700 && (
              <div
                style={{
                  borderBottom: '1px solid #ccc',
                  width: 'calc(100% - 100px)',
                }}
              />
            )}
            <div className={styles['date-wrapper']}>
              <span>Start Date: </span>
              <Input
                type="date"
                name="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                onBlur={(e) => setRefresh(refresh + 1)}
              />
            </div>
            <div className={styles['date-wrapper']}>
              <span>End Date: </span>
              <Input
                type="date"
                name="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                onBlur={(e) => setRefresh(refresh + 1)}
              />
            </div>
          </div>
          <div>
            {newSearch ? (
              <LoadingLottie height="400px" />
            ) : Object.keys(formattedNotifications).length ? (
              Object.keys(formattedNotifications).map((noti) => (
                <div className={styles['noti-content']}>
                  <ul className={styles['noti-date-ul']}>
                    <div className={styles['noti-row']}>
                      <li className={styles['noti-date']}>{noti}</li>
                      <div className={styles['noti-wrapper']}>
                        <ul
                          style={{ listStyleType: '' }}
                          className={styles['noti-content-ul']}
                        >
                          {formattedNotifications[noti].map((notiObj) => (
                            <>
                              <div
                                key={notiObj?.id}
                                className={styles['noti-title']}
                              >
                                <li>
                                  {notiObj?.notificationSource === '' ? (
                                    <div>
                                      <a
                                        className={styles['noti-title-span']}
                                        href={notiObj?.content}
                                      >
                                        {notiObj.title}
                                      </a>
                                      <a
                                        className={styles['noti-read-more']}
                                        href={notiObj?.content}
                                      >
                                        [Read More]
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <span
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        className={styles['noti-title-span']}
                                        onClick={() => {
                                          if (!token) {
                                            setSignupModal(true);
                                          } else {
                                            handleNotificationClick(notiObj);
                                          }
                                        }}
                                      >
                                        {notiObj.title}{' '}
                                      </span>
                                      <span
                                        onClick={() =>
                                          !token
                                            ? setSignupModal(true)
                                            : handleNotificationClick(notiObj)
                                        }
                                        className={styles['noti-read-more']}
                                      >
                                        [Read More]
                                      </span>
                                    </div>
                                  )}
                                </li>
                              </div>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              ))
            ) : (
              <NoData />
            )}
            {loading ? (
              <LoadingLottie height="200px" />
            ) : notificationCompleted ? (
              <div className={styles['load-more']}>
                <>
                  You're all caught up! &nbsp;
                  {window.scrollY !== 0 && (
                    <Button
                      className={styles['topScroll']}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Go to Top
                    </Button>
                  )}
                </>
              </div>
            ) : (
              <div className={styles['load-more']}>
                <Button
                  color="transparent"
                  className={styles.loadMoreBtn}
                  onClick={() =>
                    !token
                      ? setSignupModal(true)
                      : setPageNumber((prevPage) => prevPage + 1)
                  }
                >
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ color: '#1a1a1a' }}
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
        {window.innerWidth < 700 && <AppPopUp />}
        <div className={styles['bottombar']}>
          <BottomBar />
        </div>
        <SignupModal
          signupModal={signupModal}
          setSignupModal={setSignupModal}
        />
        <>
          <Modal
            show={showNotificationModal}
            onHide={() => {
              setShowNotificationModal(false);
            }}
            centered
            size="lg"
            className={styles['noti-modal']}
          >
            {' '}
            {showNotificationModal && window.innerWidth > 700 && (
              <Button
                onClick={handlePreviousNotification}
                disabled={currentNotificationIndex === 0}
                className={styles['prev-btn']}
              >
                <FontAwesomeIcon icon={faBackwardStep} />
              </Button>
            )}
            <Modal.Header className={styles['noti-modal-header']}>
              <Modal.Title className={styles['noti-modal-header-title']}>
                {modalData.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                className={styles['noti-desc-modal']}
                dangerouslySetInnerHTML={{
                  __html: modalData.plainContent,
                }}
              />
            </Modal.Body>
            <Modal.Footer className={styles['noti-modal-footer']}>
              <a
                href={modalData.contentUrl}
                target="_blank"
                rel="noreferrer"
                style={{
                  margin: '0 auto 0 0',
                  textDecoration: 'none',
                  fontSize: '12px',
                }}
              >
                <span
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                  }}
                >
                  Source:
                </span>{' '}
                {modalData.notificationSource}
              </a>

              <PinButton
                self={modalData.notiObj._userId === id}
                pinId={modalData?.pinId}
                selectedQues={modalData.notiObj as unknown as IPost}
                type={Pin.UPDATE}
              />
            </Modal.Footer>
            {showNotificationModal && window.innerWidth > 700 && (
              <Button
                onClick={handleNextNotification}
                disabled={
                  currentNotificationIndex === notificationsArray.length - 1
                }
                className={styles['next-btn']}
              >
                <FontAwesomeIcon icon={faForwardStep} />
              </Button>
            )}
            {showNotificationModal && window.innerWidth < 700 && (
              <div className={styles.prevBtnWrapper}>
                <Button
                  onClick={handlePreviousNotification}
                  disabled={currentNotificationIndex === 0}
                  className={styles['prev-btn-mobile']}
                >
                  <FontAwesomeIcon icon={faBackwardStep} />
                </Button>
                <Button
                  onClick={handleNextNotification}
                  disabled={
                    currentNotificationIndex === notificationsArray.length - 1
                  }
                  className={styles['prev-btn-mobile']}
                >
                  <FontAwesomeIcon icon={faForwardStep} />
                </Button>
              </div>
            )}
          </Modal>
        </>
      </Layout>
    </>
  );
}
