import React, { useContext, useEffect, useState } from 'react';
import ChatListMobile from 'src/components/message/ChatListMobile';
import ChatWindowMobile from 'src/components/message/ChatWindowMobile';
import SignupModal from 'src/components/stepper/SignupModal';
import { MessageContext } from 'src/context/message.context';
import Layout from 'src/layouts/layout';
import { fetchRecentChatList } from 'src/services/message-service/message-service';
import { useLocation } from 'react-router-dom';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
const MessageMobile: React.FC = () => {
  const { selectedFriend, fetchMessageFromId } = useContext(MessageContext);
  const token = localStorage.getItem('token');
  const currentUrl = window.location.href;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fetchedRecentList, setFechedRecentList] = useState<any>([]);
  const [signupModal, setSignupModal] = useState(false);
  const location = useLocation();

  const getUserDetails = async () => {
    try {
      const params = new URLSearchParams(location.search);

      const userId = params.get('userid');

      if (userId) {
        const loggedInUser = localStorage.getItem('id');
        const userRes = await fetchUserDetails(userId);
        //@ts-ignore
        fetchMessageFromId(loggedInUser, userRes);
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  useEffect(() => {
    if (!token) {
      setSignupModal(true);
    } else if (currentUrl.includes('message')) {
      fetchRecentChat();
    } else {
      getUserDetails();
    }
  }, [token, location]);

  const fetchRecentChat = async () => {
    const user: any = localStorage.getItem('user');

    if (!token || !user) {
      return;
    }
    if (user) {
      const userData = JSON.parse(user);
      try {
        const recentChatList = await fetchRecentChatList(userData._id);
        setFechedRecentList(recentChatList?.data);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const handleRefreshClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await fetchRecentChat();
  };

  return (
    <>
      {selectedFriend.length === 0 &&
        !window.location.href.includes('chatwindow?userid') && (
          <Layout contentBordered>
            <div>
              <ChatListMobile refreshList={fetchedRecentList} />
            </div>
          </Layout>
        )}

      {selectedFriend.map((selData: any, index: number) => (
        <ChatWindowMobile
          key={`${selData?.user?._id}-${index}`} // Ensures unique keys
          messageListData={selData}
          handleRefreshClick={handleRefreshClick}
        />
      ))}

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default MessageMobile;
