import axiosInstance from 'src/config/api';
import { IAttachment } from '../post-service/post-service.interface';
import {
  ITrend,
  ITrendComment,
  ITrendCommentResponse,
  ITrendResponse,
} from './trend-service.interface';

export const fetchTrends = async (
  pageNumber: number,
  sortMethod: string,
  trendId?: string,
  trendName?: string
): Promise<ITrendResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const params: any = {
      pageNumber,
      pageSize: 15,
      sortMethod,
      // _id: trendId,
    };
    if (trendId) {
      params._id = trendId;
    }
    if (trendName) {
      params.trendName = trendName;
    }
    const response = await axiosInstance.get(`${baseUrl}/api/v1/trend`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
  //@ts-ignore
  return;
};

export const fetchTrendSearch = async (
  trendName?: string
): Promise<ITrendResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const params: any = {
      pageNumber: 1,
      pageSize: 15,
    };

    if (trendName) {
      params.trendName = trendName;
    }
    const response = await axiosInstance.get(`${baseUrl}/api/v1/trend`, {
      params,
    });

    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
  //@ts-ignore
  return;
};

export const createTrend = async (
  attachments: IAttachment[],
  content: string,
  plainContent: string,
  trendTimer: number
  //   endedOn: string
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.post(`${baseUrl}/api/v1/trend`, {
      attachments,
      content,
      plainContent,
      trendTimer,
    });
    return response.data;
  } catch (error) {
    console.log('error while uploading the docs', error);
  }
  return;
};

export const postTrendComment = async (
  trendId: string,
  content: string
): Promise<ITrendComment> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.post(`${baseUrl}/api/v1/trend/${trendId}/comment`, {
      content,
    })
  ).data;
};

export const deleteTrend = async (trendId: string): Promise<ITrend> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.delete(`${baseUrl}/api/v1/trend/${trendId}`))
    .data;
};

export const deleteTrendComment = async (
  commentId: string
): Promise<ITrend> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(`${baseUrl}/api/v1/trend/comment/${commentId}`)
  ).data;
};

export const getTrendComment = async (
  trendId: string,
  pageNumber: number,
  pageSize: number
): Promise<ITrendCommentResponse> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.get(
      `${baseUrl}/api/v1/trend/${trendId}/comment?populate=_userId&pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
  ).data;
};

export const trendUpvote = async (trendId: string): Promise<ITrend> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (await axiosInstance.post(`${baseUrl}/api/v1/trend/${trendId}/upvote`))
    .data;
};

export const deleteTrendUpvote = async (
  terndId: string,
  userId: string
): Promise<ITrend> => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    await axiosInstance.delete(
      `${baseUrl}/api/v1/trend/${terndId}/upvote/${userId}`
    )
  ).data;
};

export const fetchAllTrendUpvotes = async (trendIds: any) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  return await axiosInstance.post(`${baseUrl}/api/v1/trend/alltrend/upvotes`, {
    trendIds,
  });
};

export const editTrend = async (
  trendId: any,
  content: string,
  trendTimer: number
) => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  try {
    const response = await axiosInstance.put(
      `${baseUrl}/api/v1/trend/${trendId}`,
      {
        content,
        trendTimer,
      }
    );
    return response.data;
  } catch (error) {
    console.log('Error while updating the trend', error);
  }
};
