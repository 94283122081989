import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../navbar/navbar.module.css';
import { fetchEventSearch } from 'src/services/event-service/event-service';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';

export default function EventsSearch({
  eventName = '',
  setEventName,
  isEventQuery,
  setIsEventQuery,
}: any) {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleEventNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventName(event.target.value);
  };

  const handleKeyPressEvent = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' || event.key === 'Return') {
      urlParams.delete('eventId');
      urlParams.set('eventName', `${eventName}`);
      navigate(`/events?${urlParams.toString()}`);
      setOptions([]);
      handleCloseDropdown();
    }
  };

  const [options, setOptions] = useState<any[]>([]);
  const getEventsByName = async () => {
    if (eventName.length > 0) {
      const res = await fetchEventSearch(eventName);
      setOptions(res.data);
    }
  };

  const handleOptionClick = (eventId: any) => {
    urlParams.delete('eventName');
    urlParams.set('eventId', `${eventId}`);
    navigate(`/events?${urlParams.toString()}`);
    setEventName('');
    setOptions([]);
    handleCloseDropdown();
  };

  const handleCloseDropdown = () => {
    if (ref.current) {
      setIsEventQuery(false);
      setOptions([]);
    }
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleCloseDropdown();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsEventQuery]);

  useEffect(() => {
    getEventsByName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName]);

  return (
    <div ref={ref}>
      <div
        className={classNames(
          window.innerWidth > 700 && isEventQuery
            ? styles['wc-search-container-active']
            : window.innerWidth > 700 && !isEventQuery
            ? styles['wc-search-container']
            : styles['wc-search-container']
        )}
      >
        <div
          className={classNames(
            styles['wc-search'],
            isEventQuery && styles['wc-search-paddingActive'],
            options.length > 0 && isEventQuery && styles['wc-search-active']
          )}
        >
          {window.innerWidth < 700 ? (
            <div className={styles['search-user-img-mobile']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                width={16}
                height={19}
              />
            </div>
          ) : (
            <div className={styles['search-user-img-windows']}>
              <img
                style={{ cursor: 'pointer' }}
                src="/images/searchIcon.svg"
                alt="searchIco"
                height={20}
              />
            </div>
          )}
          <input
            type="text"
            ref={inputRef}
            value={eventName}
            onChange={handleEventNameChange}
            placeholder=" Search by Event name"
            className={styles['wc-search-input']}
            onKeyDown={handleKeyPressEvent}
            onFocus={() => setIsEventQuery(true)}
            enterKeyHint="go"
          />{' '}
          {eventName.length > 0 && (
            <div className={styles.navClearBtnWrapper}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                onClick={() => {
                  setEventName('');
                  navigate('/events');
                  handleCloseDropdown();
                }}
              />
            </div>
          )}
        </div>

        {isEventQuery && options.length > 0 && (
          <Dropdown.Menu
            show
            className={styles.navbarSeacrhDropdown}
            style={{
              width: ref?.current?.offsetWidth,
              maxWidth: ref?.current?.offsetWidth,
            }}
          >
            {options.map((option) => (
              <Dropdown.Item
                style={{
                  padding: '8px 12px ',
                  borderBottom: '1px solid #ccc',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                key={option.value}
                className={styles['dropdown-option']}
                onClick={() => {
                  handleOptionClick(option._id);
                }}
              >
                {option.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </div>
    </div>
  );
}
